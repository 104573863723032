import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList } from '../../../../store/reducers/lot-payment';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';

interface IProps {
    lot_id: number
}

const ClientLotPayments: React.FunctionComponent<IProps> = ({ lot_id }) => {
    // Global
    const navigate = useNavigate();
    const lotPayment = useSelector((state: RootState) => state.lotPayment);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            lot_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                lot_id
            }));
    }, []);

    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="client-lot-payments-table"
            >
                {lotPayment.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cashier</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cashier Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Option</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Note</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cash</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lotPayment.list && lotPayment.list.length > 0
                                ?
                                lotPayment.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/branches/${item.client_branch_id}`)}
                                                >
                                                    {item.client_branch_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/cashiers/${item.cashier_id}`)}
                                                >
                                                    {item.cashier_first_name} {item.cashier_middle_name} {item.cashier_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/branches/${item.cashier_branch_id}`)}
                                                >
                                                    {item.cashier_branch_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/branches/${item.payment_branch_id}`)}
                                                >
                                                    {item.payment_branch_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_type}</TableCell>
                                            <TableCell>{item.payment_note}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.amount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.cash)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {dayjs(item.due_date).format('MMMM D, YYYY')}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {dayjs(item.payment_date).format('MMMM D, YYYY hh:mm A')}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!lotPayment.isListLoading && lotPayment.listPagination && lotPayment.listPagination.total > 0 ?
                <Box
                    className="client-lot-payments-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {lotPayment.listPagination.fromData} - {lotPayment.listPagination.toData} out of {lotPayment.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={lotPayment.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
        </>
    )
}

export default ClientLotPayments;