import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import myAccountReducer from "./reducers/my-account";
import branchReducer from "./reducers/branch";
import areaReducer from "./reducers/area";
import paymentOptionReducer from "./reducers/payment-option";
import clientReducer from "./reducers/client";
import cashierReducer from "./reducers/cashier";
import agentReducer from "./reducers/agent";
import lotReducer from "./reducers/lot";
import lotClientReducer from "./reducers/lot-client";
import lotPaymentReducer from "./reducers/lot-payment";
import clientLotReducer from "./reducers/client-lot";
import agentClientReducer from "./reducers/agent-client";
import agentCommissionDoneReducer from "./reducers/agent-commission-done";
import agentCommissionNotDoneReducer from "./reducers/agent-commission-not-done";
import dailyReportReducer from "./reducers/daily-report";
import monthlyReportReducer from "./reducers/monthly-report";
import yearlyReportReducer from "./reducers/yearly-report";
import salesDirectorClientReducer from "./reducers/sales-director-client";
import salesDirectorCommissionDoneReducer from "./reducers/sales-director-commission-done";
import salesDirectorCommissionNotDoneReducer from "./reducers/sales-director-commission-not-done";
import salesDirectorReducer from "./reducers/sales-director";
import unitManagerClientReducer from "./reducers/unit-manager-client";
import unitManagerCommissionDoneReducer from "./reducers/unit-manager-commission-done";
import unitManagerCommissionNotDoneReducer from "./reducers/unit-manager-commission-not-done";
import unitManagerReducer from "./reducers/unit-manager";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        myAccount: myAccountReducer,
        branch: branchReducer,
        area: areaReducer,
        paymentOption: paymentOptionReducer,
        client: clientReducer,
        cashier: cashierReducer,
        agent: agentReducer,
        lot: lotReducer,
        lotClient: lotClientReducer,
        lotPayment: lotPaymentReducer,
        clientLot: clientLotReducer,
        agentClient: agentClientReducer,
        agentCommissionDone: agentCommissionDoneReducer,
        agentCommissionNotDone: agentCommissionNotDoneReducer,
        dailyReport: dailyReportReducer,
        monthlyReport: monthlyReportReducer,
        yearlyReport: yearlyReportReducer,
        salesDirector: salesDirectorReducer,
        salesDirectorClient: salesDirectorClientReducer,
        salesDirectorCommissionDone: salesDirectorCommissionDoneReducer,
        salesDirectorCommissionNotDone: salesDirectorCommissionNotDoneReducer,
        unitManager: unitManagerReducer,
        unitManagerClient: unitManagerClientReducer,
        unitManagerCommissionDone: unitManagerCommissionDoneReducer,
        unitManagerCommissionNotDone: unitManagerCommissionNotDoneReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch