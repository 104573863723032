import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, Select, MenuItem, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getPayments, pay } from '../../../../store/reducers/client-lot';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';
import { IClientLotPay } from '../../../../types/client-lot/pay';
import { emptyAutocompleteList, getAutocompleteList, getList } from '../../../../store/reducers/payment-option';
import { IAutocomplete } from '../../../../types/autocomplete';
import { showWarningToast } from '../../../../toast/warn';
import getRole from '../../../../utils/get-role';
import toFixed from '../../../../utils/to-fixed';
import isWholeNumber from '../../../../utils/is-whole-number';
import addOne from '../../../../utils/add-one';

interface IProps {
    client_lot_id: number,
    is_paid: boolean
}

const PaymentClientLotPayments: React.FunctionComponent<IProps> = ({ is_paid, client_lot_id }) => {

    // Global
    const navigate = useNavigate();
    const clientLot = useSelector((state: RootState) => state.clientLot);
    const paymentOption = useSelector((state: RootState) => state.paymentOption);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getPayments({
            page: value,
            client_lot_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN')) {
            dispatchAction(getPayments({
                page: 1,
                client_lot_id
            }));

            dispatchAction(getList({
                page: 1,
                search: ''
            }))
        }

    }, []);

    useEffect(() => {
        if (clientLot.isRefetchPaymentList)
            dispatchAction(getPayments({
                page: page,
                client_lot_id
            }));

    }, [clientLot.isRefetchPaymentList]);

    // Create payment
    const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState(false);
    const createPaymentModalHandleOpen = () => setIsCreatePaymentModalOpen(true);
    const createPaymentModalHandleClose = () => setIsCreatePaymentModalOpen(false);
    const payDataPlaceholder = {
        client_lot_id: client_lot_id,
        payment_note: '',
        amount: 0,
        cash: 0,
        payment_type_id: 0,
        months: 0
    }
    const [payData, setPayData] = useState<IClientLotPay>(payDataPlaceholder);

    // Payment option autocomplete
    const [payPaymentOptionAutocompleteSelected, setPayPaymentOptionAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [payPaymentOptionAutocompleteSearchId, setCreateClientAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [payPaymentOptionAutocompleteInput, setCreateClientAutocompleteInput] = useState('');
    const [payPaymentOptionAutocompletePage, setCreateClientAutocompletePage] = useState(1);

    const payPaymentOptionAutocompleteNextPage = () => {
        if (payPaymentOptionAutocompletePage + 1 <= paymentOption.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: payPaymentOptionAutocompletePage + 1,
                search: payPaymentOptionAutocompleteInput,
                addToCurrent: true,
            }));

            setCreateClientAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onPayPaymentOptionAutocompleteInputChange = (search: string) => {

        setPayPaymentOptionAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateClientAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = paymentOption.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(payPaymentOptionAutocompleteSearchId);

        setCreateClientAutocompleteSearchId(setTimeout(() => payPaymentOptionAutocompleteSearch(search), 300))
    }

    const payPaymentOptionAutocompleteSearch = (search: string) => {
        setCreateClientAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const payClickEvent = () => {
        if (
            !(payPaymentOptionAutocompleteSelected.value && payPaymentOptionAutocompleteSelected.value > 0)
        ) {
            showWarningToast('Search and select a payment method');
            return;
        }

        const monthlyAmortization = addOne(clientLot.singleData.lot_price_per_month);
        const balance = monthlyAmortization * (Number(clientLot.singleData.lot_months_to_pay) - Number(clientLot.singleData.payment_count));

        if (payData.amount < monthlyAmortization) {
            showWarningToast(`Amount must be ${toMoney(monthlyAmortization)}`);
            return;
        }

        if (payData.cash < payData.amount) {
            showWarningToast('Cash is lesser than the amount');
            return;
        }

        if (payData.amount > monthlyAmortization) {
            if (!isWholeNumber((payData.amount / monthlyAmortization).toString())) {
                showWarningToast(`Amount must be divisible by ${toMoney(monthlyAmortization)}`);
                return;
            }
        }

        if (payData.amount > balance) {
            showWarningToast(`Amount must not exceed the balance`);
            return;
        }

        dispatchAction(pay({
            client_lot_id,
            payment_type_id: payPaymentOptionAutocompleteSelected.value,
            amount: payData.amount,
            cash: payData.cash,
            payment_note: payData.payment_note,
            months: 0
        }));
    }

    useEffect(() => {

        if (clientLot.isPaySuccess)
            setIsCreatePaymentModalOpen(false);

    }, [clientLot.isPaySuccess])

    return (
        <>
            {!is_paid ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createPaymentModalHandleOpen}
                >
                    Create Payment
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="client-lot-payments-table"
            >
                {clientLot.isPaymentListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cashier</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cashier Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Option</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Note</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cash</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientLot.paymentList && clientLot.paymentList.length > 0
                                ?
                                clientLot.paymentList.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/branches/${item.client_branch_id}`)}
                                                    >
                                                        {item.client_branch_name}
                                                    </Link>
                                                    : item.client_branch_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/cashiers/${item.cashier_id}`)}
                                                    >
                                                        {item.cashier_first_name} {item.cashier_middle_name} {item.cashier_last_name}
                                                    </Link>
                                                    : `${item.cashier_first_name} ${item.cashier_middle_name} ${item.cashier_last_name}`}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/branches/${item.cashier_branch_id}`)}
                                                    >
                                                        {item.cashier_branch_name}
                                                    </Link>
                                                    : item.cashier_branch_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/branches/${item.payment_branch_id}`)}
                                                    >
                                                        {item.payment_branch_name}
                                                    </Link>
                                                    : item.payment_branch_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_type}</TableCell>                                            <TableCell>GCash ref number 12345</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.amount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.cash)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {dayjs(item.due_date).format('MMMM D, YYYY')}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {dayjs(item.payment_date).format('MMMM D, YYYY hh:mm A')}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => { localStorage.setItem('print', JSON.stringify(item)); window.open(`/receipt/`, '_blank');}} variant="contained" color="warning" startIcon={<PrintIcon />}>
                                                        Print Receipt
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!clientLot.isPaymentListLoading && clientLot.paymentListPagination && clientLot.paymentListPagination.total > 0 ?
                <Box
                    className="client-lot-payments-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {clientLot.paymentListPagination.fromData} - {clientLot.paymentListPagination.toData} out of {clientLot.paymentListPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={clientLot.paymentListPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isCreatePaymentModalOpen}
                className="payment-options-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="payment-options-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Payment
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Month(s)"
                            variant="outlined"
                            fullWidth
                            required
                            value={payData.months}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setPayData((previousVal: IClientLotPay) => {
                                        return { ...previousVal, months: Number(e.target.value) };
                                    });

                                    const monthlyAmortization = addOne(clientLot.singleData.lot_price_per_month);
                                    const months = Number(e.target.value);
                                    const amount = monthlyAmortization * months;

                                    setPayData((previousVal: IClientLotPay) => {
                                        return { ...previousVal, amount  };
                                    })
                                }
                            }}
                            disabled={clientLot.isPayLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Amount"
                            variant="outlined"
                            fullWidth
                            required
                            value={payData.amount}
                            disabled={true}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Cash"
                            variant="outlined"
                            fullWidth
                            required
                            value={payData.cash}
                            onChange={(e) => {
                                setPayData((previousVal: IClientLotPay) => {
                                    return { ...previousVal, cash: Number(e.target.value) };
                                })
                            }}
                            disabled={clientLot.isPayLoading}
                        />
                        <Box
                            className="payment-change-container"
                        >
                            <Typography variant="h6">
                                Change
                            </Typography>
                            <Typography>
                                {toMoney(payData.cash - payData.amount)}
                            </Typography>
                        </Box>
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        payPaymentOptionAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={paymentOption.isAutocompleteListLoading}
                            options={paymentOption.autocompleteList}
                            value={payPaymentOptionAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setPayPaymentOptionAutocompleteSelected(newValue)
                            }}
                            inputValue={payPaymentOptionAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateClientAutocompleteInput(newInputValue);
                                onPayPaymentOptionAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Search Payment Option" />}
                            disabled={paymentOption.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={5}
                            minRows={5}
                            value={payData.payment_note}
                            onChange={(e) => {
                                setPayData((previousVal: IClientLotPay) => {
                                    return { ...previousVal, payment_note: e.target.value };
                                })
                            }}
                            disabled={clientLot.isPayLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={payClickEvent}
                            disabled={clientLot.isPayLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientLot.isPayLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Pay
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createPaymentModalHandleClose}
                            disabled={clientLot.isPayLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default PaymentClientLotPayments;