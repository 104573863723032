import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList, terminate } from '../../../../store/reducers/client-lot';
import { IClientLotListItem } from '../../../../types/client-lot/list-item';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import PaymentsIcon from '@mui/icons-material/Payments';
import getRole from '../../../../utils/get-role';
import { ITerminate } from '../../../../types/lot/terminate';
import toFixed from '../../../../utils/to-fixed';
import addOne from '../../../../utils/add-one';

interface IProps {
    client_id: number
}

const ClientLots: React.FunctionComponent<IProps> = ({ client_id }) => {
    // Global
    const navigate = useNavigate();
    const clientLot = useSelector((state: RootState) => state.clientLot);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            client_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                client_id
            }));
    }, []);

    useEffect(() => {
        if (clientLot.isRefetchList)
            dispatchAction(getList({
                page,
                client_id
            }));

    }, [clientLot.isRefetchList]);

    // Terminate
    const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);
    const terminateModalHandleClose = () => setIsTerminateModalOpen(false);
    const [terminateData, setTerminateData] = useState<ITerminate>({
        lot_id: 0,
        terminate_note: '',
        lot_block: 0,
        lot_lot: 0,
        is_permanently_delete: false
    });

    const openTerminateModal = (data: IClientLotListItem) => {

        setTerminateData({ ...data, is_permanently_delete: false });
        setIsTerminateModalOpen(true);
    }

    const terminateClickEvent = () => {
        if (terminateData && terminateData.lot_id)
            dispatchAction(terminate(terminateData));
    }

    useEffect(() => {
        if (!clientLot.isTerminateLoading)
            setIsTerminateModalOpen(false);

    }, [clientLot.isTerminateLoading]);

    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="client-lots-table"
            >
                {clientLot.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Price Per Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Director</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Manager</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Count (Months)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Monthly Amortization</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Delay (Days)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Terminate Note</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientLot.list && clientLot.list.length > 0
                                ?
                                clientLot.list.map(item => {
                                    return (
                                        <TableRow key={item.client_lot_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}`)}
                                                    >
                                                        {item.area_name}
                                                    </Link>
                                                    : item.area_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}}/lot/${item.lot_id}`)}
                                                    >
                                                        Block {item.lot_block} Lot {item.lot_lot}
                                                    </Link>
                                                    : `Block ${item.lot_block} Lot ${item.lot_lot}`}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_square_meters}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(Number(item.lot_price_per_square_meters))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/sales-directors/${item.sales_director_id}`)}
                                                >
                                                    {item.sales_director_first_name} {item.sales_director_middle_name} {item.sales_director_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/unit-managers/${item.unit_manager_id}`)}
                                                >
                                                    {item.unit_manager_first_name} {item.unit_manager_middle_name} {item.unit_manager_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/agents/${item.agent_id}`)}
                                                >
                                                    {item.agent_first_name} {item.agent_middle_name} {item.agent_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_count} / {item.lot_months_to_pay}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count)))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{(item.delayed_days > 0 ? item.delayed_days : 0)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_date).format('MMMM D, YYYY hh:mm A') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_due_date).format('MMMM D, YYYY') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.sold_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={dayjs(item.due_date).format('MMMM D, YYYY')} variant="outlined" color={item.delayed_days > 0 ? 'error' : 'primary'} />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {Number(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count))) === 0 ? <Chip label='Paid' color="primary" /> :
                                                    <Chip label={item.is_terminated ? 'Terminated' : 'Active'} color={item.is_terminated ? 'error' : 'success'} />
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.terminate_note ? item.terminate_note : 'N/A'}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Button sx={{ marginRight: '10px' }} onClick={() => navigate(`/payments/${client_id}/lot/${item.client_lot_id}`)} variant="contained" color="primary" startIcon={<PaymentsIcon />}>
                                                    Payment
                                                </Button>
                                                <Button sx={{ display: getRole() === 1 ? 'default' : 'none' }} disabled={item.is_terminated || Number(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count))) === 0} onClick={() => openTerminateModal(item)} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                                                    Terminate
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!clientLot.isListLoading && clientLot.listPagination && clientLot.listPagination.total > 0 ?
                <Box
                    className="client-lots-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {clientLot.listPagination.fromData} - {clientLot.listPagination.toData} out of {clientLot.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={clientLot.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isTerminateModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Terminate Contract
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to terminate client lot 'Block {terminateData?.lot_block} Lot {terminateData?.lot_lot}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={terminateData.terminate_note}
                            onChange={(e) => {
                                setTerminateData((previousVal: ITerminate) => {
                                    return { ...previousVal, terminate_note: e.target.value };
                                })
                            }}
                            disabled={clientLot.isTerminateLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={terminateData.is_permanently_delete}
                                    onChange={(e) => {
                                        setTerminateData((previousVal: ITerminate) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={terminateClickEvent}
                            disabled={clientLot.isTerminateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientLot.isTerminateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={terminateModalHandleClose}
                            disabled={clientLot.isTerminateLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ClientLots;