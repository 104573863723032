import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import Lots from '../lots';
import ClientLotPayments from '../client-lot-payments';
import ClientLots from '../client-lots';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { _delete, getSingle, getSingleForEdit, update } from '../../../../store/reducers/client';
import { IClientEdit } from '../../../../types/client/edit';
import { IClient } from '../../../../types/client/single';
import { showWarningToast } from '../../../../toast/warn';
import { getAutocompleteList, emptyAutocompleteList } from '../../../../store/reducers/branch';
import { IAutocomplete } from '../../../../types/autocomplete';
import getRole from '../../../../utils/get-role';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SingleClient: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const branch = useSelector((state: RootState) => state.branch);
    const client = useSelector((state: RootState) => state.client);
    const clientLot = useSelector((state: RootState) => state.clientLot);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingle(parseInt(id)));
    }, []);

    useEffect(() => {
        if (client.isRefetchList && id)
            dispatchAction(getSingle(parseInt(id)));

    }, [client.isRefetchList]);

    // Edit
    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: '',
        branch_id: 0,
    }
    const [editData, setEditData] = useState<IClientEdit>(editDataPlaceholder);
    const [editBranchAutocompleteInput, setEditBranchAutocompleteInput] = useState('');
    const [editBranchAutocompleteSelected, setEditBranchAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [editAutocompleteSearchId, setEditAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [editBranchAutocompletePage, setEditBranchAutocompletePage] = useState(1);

    const getEditData = () => {
        if (!id)
            return;

        dispatchAction(getSingleForEdit(parseInt(id)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (client.isEditGetSingleSuccess) {
            setEditData(client.editSingleData);
            setEditBranchAutocompleteSelected({
                value: client.editSingleData.branch_id,
                label: client.editSingleData.branch_name
            });
            setEditBranchAutocompleteInput(client.editSingleData.branch_name);
            dispatchAction(emptyAutocompleteList());
        }

    }, [client.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (
            !editData.first_name
            || !editData.middle_name
            || !editData.last_name
            || !editData.contact_number
            || !editData.address
            || !(editBranchAutocompleteSelected.value && editBranchAutocompleteSelected.value > 0)
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update({ ...editData, branch_id: editBranchAutocompleteSelected.value }));
    }

    useEffect(() => {
        if (client.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [client.isUpdateSuccess]);

    const onEditAutocompleteInputChange = (search: string) => {

        setEditBranchAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setEditBranchAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = branch.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(editAutocompleteSearchId);

        setEditAutocompleteSearchId(setTimeout(() => editAutocompleteSearch(search), 300))
    }

    const editAutocompleteSearch = (search: string) => {
        setEditBranchAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const editAutocompleteNextPage = () => {
        if (editBranchAutocompletePage + 1 <= branch.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: editBranchAutocompletePage + 1,
                search: editBranchAutocompleteInput,
                addToCurrent: true
            }));

            setEditBranchAutocompletePage(previousVal => previousVal + 1);
        }
    }

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IClient>();

    const openDeleteModal = () => {

        if (!client.singleData)
            return;

        setDeleteData(client.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!client.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [client.isDeleteLoading]);

    useEffect(() => {

        if (client.isDeleteSuccess)
            navigate('/clients')

    }, [client.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/clients')}
                    >
                        Clients
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {client.singleData.first_name} {client.singleData.middle_name} {client.singleData.last_name}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="client-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: client.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                {getRole() === 1 ?
                <Box
                    className="client-info-edit-button"
                >
                    <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                        Edit
                    </Button>
                    <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Box>
                : null }
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {client.singleData.first_name}
                    </Typography>
                </Box>
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {client.singleData.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {client.singleData.last_name}
                    </Typography>
                </Box>
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {client.singleData.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {client.singleData.address}
                    </Typography>
                </Box>
                <Box
                    className="client-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Branch
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/branches/${client.singleData.branch_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {client.singleData.branch_name}
                            </Link>
                            : client.singleData.branch_name}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Lots (${clientLot.listPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ClientLots client_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Client
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: client.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.first_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.first_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.middle_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.middle_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.last_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.last_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.contact_number ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.contact_number}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.address}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        editAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={branch.isAutocompleteListLoading}
                            options={branch.autocompleteList}
                            value={editBranchAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setEditBranchAutocompleteSelected(newValue)
                            }}
                            inputValue={editBranchAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setEditBranchAutocompleteInput(newInputValue);
                                onEditAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Branch" />}
                            disabled={client.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={client.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: client.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={client.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Client
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete client '{deleteData?.first_name} {deleteData?.middle_name} {deleteData?.last_name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={client.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: client.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={client.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleClient;