import { Box } from '@mui/system';
import './styles.css';
import SideBar from '../../components/commons/side-bar';
import Header from '../../components/commons/header';
import HorizontalDivs from '../../components/commons/horizontal-divs';
import View from '../../components/commons/view';
import Branches from '../../components/page-components/dashboard/branches';
import { useEffect } from 'react';
import Areas from '../../components/page-components/dashboard/areas';
import PaymentOptions from '../../components/page-components/dashboard/payment-options';
import Clients from '../../components/page-components/dashboard/clients';
import Cashiers from '../../components/page-components/dashboard/cashiers';
import Agents from '../../components/page-components/dashboard/agents';
import SingleBranch from '../../components/page-components/dashboard/single-branch';
import SingleArea from '../../components/page-components/dashboard/single-area';
import SingleLot from '../../components/page-components/dashboard/single-lot';
import SingleClient from '../../components/page-components/dashboard/single-client';
import SingleAgent from '../../components/page-components/dashboard/single-agent';
import SingleCashier from '../../components/page-components/dashboard/single-cashier';
import SinglePayment from '../../components/page-components/dashboard/single-payment';
import SinglePaymentClientLot from '../../components/page-components/dashboard/single-payment-client-lot';
import DueDates from '../../components/page-components/dashboard/due-dates';
import Payments from '../../components/page-components/dashboard/payments';
import Delays from '../../components/page-components/dashboard/delays';
import DailyReport from '../../components/page-components/dashboard/daily-report';
import MonthlyReport from '../../components/page-components/dashboard/monthly-report';
import YearlyReport from '../../components/page-components/dashboard/yearly-report';
import MyAccount from '../../components/page-components/dashboard/my-account';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import SalesDirector from '../../store/reducers/sales-director';
import SingleSalesDirector from '../../components/page-components/dashboard/single-sales-director';
import SalesDirectors from '../../components/page-components/dashboard/sales-director';
import UnitManagers from '../../components/page-components/dashboard/unit-manager';
import SingleUnitManager from '../../components/page-components/dashboard/single-unit-manager';

interface IProps {
    page: string
}

const Dashboard: React.FunctionComponent<IProps> = (props) => {

    const myAccount = useSelector((state: RootState) => state.myAccount);

    return (
        <>
            <Box
                className="dashboard-container"
            >
                <Header />
                {myAccount.accountDetails.role_id === 1 || myAccount.accountDetails.role_id === 2 ?
                    <HorizontalDivs>

                        <SideBar page={props.page} />
                        <View>
                            {props.page === 'branches' ? <Branches /> : null}
                            {props.page === 'areas' ? <Areas /> : null}
                            {props.page === 'payment-options' ? <PaymentOptions /> : null}
                            {props.page === 'clients' ? <Clients branch_id={0} /> : null}
                            {props.page === 'cashiers' ? <Cashiers branch_id={0} /> : null}
                            {props.page === 'agents' ? <Agents branch_id={0} /> : null}
                            {props.page === 'single-branch' ? <SingleBranch /> : null}
                            {props.page === 'single-area' ? <SingleArea /> : null}
                            {props.page === 'single-lot' ? <SingleLot /> : null}
                            {props.page === 'single-client' ? <SingleClient /> : null}
                            {props.page === 'single-agent' ? <SingleAgent /> : null}
                            {props.page === 'single-cashier' ? <SingleCashier /> : null}
                            {props.page === 'payments' ? <Payments /> : null}
                            {props.page === 'single-payment' ? <SinglePayment /> : null}
                            {props.page === 'single-payment-client-lot' ? <SinglePaymentClientLot /> : null}
                            {props.page === 'due-dates' ? <DueDates /> : null}
                            {props.page === 'delays' ? <Delays /> : null}
                            {props.page === 'daily-report' ? <DailyReport /> : null}
                            {props.page === 'monthly-report' ? <MonthlyReport /> : null}
                            {props.page === 'yearly-report' ? <YearlyReport /> : null}
                            {props.page === 'my-account' ? <MyAccount /> : null}
                            {props.page === 'sales-directors' ? <SalesDirectors branch_id={0} /> : null}
                            {props.page === 'single-sales-director' ? <SingleSalesDirector /> : null}
                            {props.page === 'unit-managers' ? <UnitManagers branch_id={0} /> : null}
                            {props.page === 'single-unit-manager' ? <SingleUnitManager /> : null}
                        </View>
                    </HorizontalDivs>
                    :
                    <>
                        <Box
                            sx={{
                                padding: '20px'
                            }}
                        >
                            {props.page === 'areas' ? <Areas /> : null}
                            {props.page === 'single-area' ? <SingleArea /> : null}
                        </Box>

                    </>
                }
            </Box>
        </>
    )
}

export default Dashboard