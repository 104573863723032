import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { IBranchListItem } from '../../../../types/branch/list-item';
import { IBranchCreate } from '../../../../types/branch/create';
import { IBranchEdit } from '../../../../types/branch/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/branch';

const Branches: React.FunctionComponent = () => {

    // Global
    const navigate = useNavigate();
    const branch = useSelector((state: RootState) => state.branch);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            search: search
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                search: ''
            }));
    }, []);

    useEffect(() => {
        if (branch.isRefetchList)
            dispatchAction(getList({
                page,
                search
            }));

    }, [branch.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            page: 1,
            search: search
        }));
    }

    const searchHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    const searchKeyPressHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13) {
            searchList();
        }
    }

    // Create
    const createDataPlaceholder = {
        name: '',
        address: ''
    }
    const [createData, setCreateData] = useState<IBranchCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleOpen = () => setIsCreateModalOpen(true);
    const createModalHandleClose = () => { 
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false)
    }

    const createClickEvent = () => {
        if (!createData.name || !createData.address) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create(createData));
    }

    useEffect(() => {
        if (branch.isCreateSuccess)
        {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);
        }   

    }, [branch.isCreateSuccess]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: '',
        address: ''
    }
    const [editData, setEditData] = useState<IBranchEdit>(editDataPlaceholder);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(()=>{

        if(branch.isEditGetSingleSuccess)
            setEditData(branch.editSingleData);

    }, [branch.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name || !editData.address) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (branch.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [branch.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IBranchListItem>();

    const openDeleteModal = (data: IBranchListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if(deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!branch.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [branch.isDeleteLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Branches
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="branches-search-container"
            >
                <FormControl
                    variant="outlined"
                    className="branches-search-input"
                    fullWidth
                >
                    <InputLabel>Search name</InputLabel>
                    <OutlinedInput
                        label="Search name"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={searchList}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={searchHandleChange}
                        value={search}
                        onKeyUp={searchKeyPressHandle}
                    />
                </FormControl>
            </Box>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={createModalHandleOpen}
            >
                Create New
            </Button>
            <TableContainer
sx= {{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="branches-table"
            >
                {branch.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Created at</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {branch.list && branch.list.length > 0
                                ?
                                branch.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                                {item.name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                                {item.address}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                                {dayjs(item.created_at).format('MMMM D, YYYY hh:mm A')}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/branches/${item.id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                    <Button onClick={() => getEditData(item.id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                        Edit
                                                    </Button>
                                                    <Button onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                        Delete
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>

            {!branch.isListLoading && branch.listPagination && branch.listPagination.total > 0 ?
                <Box
                    className="branches-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {branch.listPagination.fromData} - {branch.listPagination.toData} out of {branch.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={branch.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}

            <Modal
                open={isCreateModalOpen}
                className="branches-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="branches-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Branch
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.name}
                            onChange={(e) => {
                                setCreateData((previousVal: IBranchCreate) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            disabled={branch.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch address"
                            variant="outlined"
                            minRows={5}
                            maxRows={5}
                            multiline
                            fullWidth
                            required
                            onChange={(e) => {
                                setCreateData((previousVal: IBranchCreate) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            value={createData.address}
                            disabled={branch.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={branch.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: branch.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={branch.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isEditModalOpen}
                className="branches-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="branches-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Branch
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: branch.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch name"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IBranchEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            value={editData.name}
                            disabled={branch.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch address"
                            variant="outlined"
                            minRows={5}
                            maxRows={5}
                            multiline
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IBranchEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            value={editData.address}
                            disabled={branch.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="success"
                            onClick={editClickEvent}
                            disabled={branch.isUpdateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: branch.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={branch.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isDeleteModalOpen}
                className="branches-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="branches-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Branch
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete branch '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={branch.isDeleteLoading}
                            >
                                <CircularProgress
                                    size="1em"
                                    sx={{
                                        display: branch.isDeleteLoading ? 'inherit' : 'none',
                                        marginRight: '10px'
                                    }}
                                />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={branch.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default Branches;