import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { _delete, getSingle, getSingleForEdit, update } from '../../../../store/reducers/branch';
import { IBranchEdit } from '../../../../types/branch/edit';
import { showWarningToast } from '../../../../toast/warn';
import { IBranchListItem } from '../../../../types/branch/list-item';
import { IBranch } from '../../../../types/branch/single';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
         
        </div>
    );
}

const SingleBranch: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const branch = useSelector((state: RootState) => state.branch);
    const cashier = useSelector((state: RootState) => state.cashier);
    const agent = useSelector((state: RootState) => state.agent);
    const client = useSelector((state: RootState) => state.client);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingle(parseInt(id)));
    }, []);

    useEffect(() => {
        if (branch.isRefetchList && id)
            dispatchAction(getSingle(parseInt(id)));
            
    }, [branch.isRefetchList]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: '',
        address: ''
    }
    const [editData, setEditData] = useState<IBranchEdit>(editDataPlaceholder);

    const getEditData = () => {
        if (!id)
            return;

        dispatchAction(getSingleForEdit(parseInt(id)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (branch.isEditGetSingleSuccess)
            setEditData(branch.editSingleData);

    }, [branch.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name || !editData.address) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (branch.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [branch.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IBranch>();

    const openDeleteModal = () => {

        if (!branch.singleData)
            return;

        setDeleteData(branch.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!branch.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [branch.isDeleteLoading]);

    useEffect(()=> {

        if(branch.isDeleteSuccess)
            navigate('/branches')

    }, [branch.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/branches')}
                    >
                        Branches
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {branch.singleData.name}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="branch-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: branch.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="branch-info-edit-button"
                >
                    <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                        Edit
                    </Button>
                    <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Box>
                <Box
                    className="branch-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Name
                    </Typography>
                    <Typography>
                        {branch.singleData.name}
                    </Typography>
                </Box>
                <Box
                    className="branch-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {branch.singleData.address}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Cashiers (${cashier.listPagination.overallTotal})`} />
                    <Tab label={`Agents (${agent.listPagination.overallTotal})`} />
                    <Tab label={`Clients (${client.listPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Cashiers branch_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Agents branch_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Clients branch_id={id ? parseInt(id) : 1} />
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="branches-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="branches-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Branch
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: branch.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch name"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IBranchEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            value={editData.name}
                            disabled={branch.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Branch address"
                            variant="outlined"
                            minRows={5}
                            maxRows={5}
                            multiline
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IBranchEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            value={editData.address}
                            disabled={branch.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="success"
                            onClick={editClickEvent}
                            disabled={branch.isUpdateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: branch.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={branch.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isDeleteModalOpen}
                className="branches-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="branches-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Branch
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete branch '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={branch.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: branch.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={branch.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleBranch;