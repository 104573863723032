import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { showSuccessToast } from '../../toast/success';
import { IClientLotListItem } from '../../types/client-lot/list-item';
import { IClientLot } from '../../types/client-lot/single';
import { IClientLotPaymentListItem } from '../../types/client-lot/payment-list-item';
import { IClientLotPay } from '../../types/client-lot/pay';
import { IClientLotDueDateListItem } from '../../types/client-lot/due-date-list-item';
import { IClientLotDelayListItem } from '../../types/client-lot/delay-list-item';
import { ITerminate } from '../../types/lot/terminate';

interface IListParams {
    page: number,
    client_id: number
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client/${queryParams.client_id}/lot`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

interface IClientLotStatusListParams {
    page: number,
    is_due_date?: boolean
}

const getClientLotStatusListQuery = async (queryParams: IClientLotStatusListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()],
            ['type', queryParams.is_due_date ? 'due-date' : 'delay']
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client-lot/all/status`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getClientLotStatusListDelay = createAsyncThunk(
    'client-lot/getClientLotStatusListDelay',
    async (queryParams: IClientLotStatusListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            queryParams.is_due_date = false;

            let result = await getClientLotStatusListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getClientLotStatusListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getClientLotStatusListDueDate = createAsyncThunk(
    'client-lot/getClientLotStatusListDueDate',
    async (queryParams: IClientLotStatusListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            queryParams.is_due_date = true;

            let result = await getClientLotStatusListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getClientLotStatusListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getList = createAsyncThunk(
    'client-lot/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

interface IPaymentListParams {
    client_lot_id: number,
    page: number
}

const getPaymentListQuery = async (queryParams: IPaymentListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client-lot/${queryParams.client_lot_id}/payment`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getPayments = createAsyncThunk(
    'client-lot/getPayments',
    async (queryParams: IPaymentListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getPaymentListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getPaymentListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const terminate = createAsyncThunk(
    'client-lot/terminate',
    async (queryParams: ITerminate, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/lot/${queryParams.lot_id}/terminate`,
                        {
                            terminate_note: queryParams.terminate_note,
                            is_permanently_delete: queryParams.is_permanently_delete
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully terminated');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const pay = createAsyncThunk(
    'client-lot/pay',
    async (queryParams: IClientLotPay, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/client-lot/${queryParams.client_lot_id}/pay`,
                        {
                            payment_type_id: queryParams.payment_type_id,
                            amount: queryParams.amount,
                            cash: queryParams.cash,
                            payment_note: queryParams.payment_note
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Payment success');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

const getSingleQuery = async (id: number) => {
    try {

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client-lot/${id}/status`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getSingle = createAsyncThunk(
    'client-lot/getSingle',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const clientLot = createSlice({
    name: 'client-lot',
    initialState: {

        // List
        list: [] as IClientLotListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // Payment List
        paymentList: [] as IClientLotPaymentListItem[],
        paymentListPagination: {} as IPagination,
        isPaymentListLoading: false,
        isRefetchPaymentList: false,

        // terminate
        isTerminateLoading: false,
        isTerminateSuccess: false,

        // Single
        isGetSingleLoading: false,
        isGetSingleSuccess: false,
        singleData: {} as IClientLot,

        // pay
        isPayLoading: false,
        isPaySuccess: false,
        isRefetchGetSingle: false,

        // Due date List
        dueDateList: [] as IClientLotDueDateListItem[],
        dueDateListPagination: {} as IPagination,
        isDueDateListLoading: false,
        isRefetchDueDateList: false,

        // delay List
        delayList: [] as IClientLotDelayListItem[],
        delayListPagination: {} as IPagination,
        isDelayListLoading: false,
        isRefetchDelayList: false,
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })

            // payment list
            .addCase(getPayments.pending, state => {
                state.isPaymentListLoading = true;
            })
            .addCase(getPayments.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.paymentList = payload.data;
                    state.paymentListPagination = payload;
                }

                state.isPaymentListLoading = false;
                state.isRefetchPaymentList = false;
            })

            // delay list
            .addCase(getClientLotStatusListDelay.pending, state => {
                state.isDelayListLoading = true;
            })
            .addCase(getClientLotStatusListDelay.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.delayList = payload.data;
                    state.delayListPagination = payload;
                }

                state.isDelayListLoading = false;
                state.isRefetchDelayList = false;
            })

            // due date list
            .addCase(getClientLotStatusListDueDate.pending, state => {
                state.isDueDateListLoading = true;
            })
            .addCase(getClientLotStatusListDueDate.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.dueDateList = payload.data;
                    state.dueDateListPagination = payload;
                }

                state.isDueDateListLoading = false;
                state.isRefetchDueDateList = false;
            })

            // terminate
            .addCase(terminate.pending, state => {
                state.isRefetchList = false;
                state.isTerminateSuccess = false;
                state.isTerminateLoading = true;
            })
            .addCase(terminate.fulfilled, (state, { payload }) => {
                state.isTerminateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isTerminateLoading = false;
            })

            // get single
            .addCase(getSingle.pending, state => {
                state.isGetSingleSuccess = false;
                state.isGetSingleLoading = true;
            })
            .addCase(getSingle.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                    state.singleData = payload.data;

                state.isGetSingleSuccess = payload.success;
                state.isGetSingleLoading = false;
            })

            // pay
            .addCase(pay.pending, state => {
                state.isRefetchGetSingle = false;
                state.isRefetchPaymentList = false;
                state.isPaySuccess = false;
                state.isPayLoading = true;
            })
            .addCase(pay.fulfilled, (state, { payload }) => {
                state.isPaySuccess = payload.success;
                state.isRefetchPaymentList = payload.success;
                state.isPayLoading = false;
                state.isRefetchGetSingle = payload.success;

                

                if (payload.success) {
                    localStorage.setItem('print', JSON.stringify(payload.data));
                    window.open(`/receipt/`);
                }
            })
    }
});

export default clientLot.reducer;