import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import dayjs from 'dayjs';
import { ILotClientListItem } from '../../../../types/lot-client/list-item';
import { ILotClientCreate } from '../../../../types/lot-client/create';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create } from '../../../../store/reducers/lot-client';
import { terminate } from '../../../../store/reducers/client-lot';
import toMoney from '../../../../utils/to-money';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IAutocomplete } from '../../../../types/autocomplete';
import { getAutocompleteList as getClientList, emptyAutocompleteList as emptyClientList } from '../../../../store/reducers/client';
import { getAutocompleteList as getAgentList, emptyAutocompleteList as emptyAgentList } from '../../../../store/reducers/agent';
import { getAutocompleteList as getSalesDirectorList, emptyAutocompleteList as emptySalesDirectorList } from '../../../../store/reducers/sales-director';
import { getAutocompleteList as getUnitManagerList, emptyAutocompleteList as emptyUnitManagerList } from '../../../../store/reducers/unit-manager';
import { getSingle } from '../../../../store/reducers/lot';
import getRole from '../../../../utils/get-role';
import { ITerminate } from '../../../../types/lot/terminate';
import isWholeNumber from '../../../../utils/is-whole-number';
import addOne from '../../../../utils/add-one';

interface IProps {
    lot_id: number,
    is_available: boolean
}

const LotClients: React.FunctionComponent<IProps> = ({ lot_id, is_available }) => {
    // Global
    const navigate = useNavigate();
    const lotClient = useSelector((state: RootState) => state.lotClient);
    const clientLot = useSelector((state: RootState) => state.clientLot);
    const client = useSelector((state: RootState) => state.client);
    const agent = useSelector((state: RootState) => state.agent);
    const salesDirector = useSelector((state: RootState) => state.salesDirector);
    const unitManager = useSelector((state: RootState) => state.unitManager);
    const dispatchAction = useDispatch<AppDispatch>();
    const [cjPercent, setCjPercent] = useState(0);
    const [salesDirectorPercent, setSalesDirectorPercent] = useState(0);
    const [unitManagerPercent, setUnitManagerPercent] = useState(0);
    const [agentPercent, setAgentPercent] = useState(0);

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            lot_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                lot_id
            }));
    }, []);

    useEffect(() => {
        if (lotClient.isRefetchList)
            dispatchAction(getList({
                page,
                lot_id
            }));

    }, [lotClient.isRefetchList]);

    // Create
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleClose = () => {
        setIsCreateModalOpen(false)
    }

    // Client
    const [createClientAutocompleteSelected, setCreateClientAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createClientAutocompleteSearchId, setCreateClientAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createClientAutocompleteInput, setCreateClientAutocompleteInput] = useState('');
    const [createClientAutocompletePage, setCreateClientAutocompletePage] = useState(1);

    const createClientAutocompleteNextPage = () => {
        if (createClientAutocompletePage + 1 <= client.autocompleteListPagination.totalPages) {
            dispatchAction(getClientList({
                page: createClientAutocompletePage + 1,
                search: createClientAutocompleteInput,
                addToCurrent: true,
                branch_id: 0
            }));

            setCreateClientAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCreateClientAutocompleteInputChange = (search: string) => {

        setCreateClientAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateClientAutocompletePage(1);
            dispatchAction(emptyClientList());
            return;
        }

        const searchingFound = client.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createClientAutocompleteSearchId);

        setCreateClientAutocompleteSearchId(setTimeout(() => createClientAutocompleteSearch(search), 300))
    }

    const createClientAutocompleteSearch = (search: string) => {
        setCreateClientAutocompletePage(1);
        dispatchAction(getClientList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // Agent
    const [createAgentAutocompleteSelected, setCreateAgentAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createAgentAutocompleteSearchId, setCreateAgentAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createAgentAutocompleteInput, setCreateAgentAutocompleteInput] = useState('');
    const [createAgentAutocompletePage, setCreateAgentAutocompletePage] = useState(1);

    const createAgentAutocompleteNextPage = () => {
        if (createAgentAutocompletePage + 1 <= agent.autocompleteListPagination.totalPages) {
            dispatchAction(getAgentList({
                page: createAgentAutocompletePage + 1,
                search: createAgentAutocompleteInput,
                addToCurrent: true,
                branch_id: 0
            }));

            setCreateAgentAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCreateAgentAutocompleteInputChange = (search: string) => {

        setCreateAgentAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateAgentAutocompletePage(1);
            dispatchAction(emptyAgentList());
            return;
        }

        const searchingFound = agent.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createAgentAutocompleteSearchId);

        setCreateAgentAutocompleteSearchId(setTimeout(() => createAgentAutocompleteSearch(search), 300))
    }

    const createAgentAutocompleteSearch = (search: string) => {
        setCreateAgentAutocompletePage(1);
        dispatchAction(getAgentList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // SalesDirector
    const [createSalesDirectorAutocompleteSelected, setCreateSalesDirectorAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createSalesDirectorAutocompleteSearchId, setCreateSalesDirectorAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createSalesDirectorAutocompleteInput, setCreateSalesDirectorAutocompleteInput] = useState('');
    const [createSalesDirectorAutocompletePage, setCreateSalesDirectorAutocompletePage] = useState(1);

    const createSalesDirectorAutocompleteNextPage = () => {
        if (createSalesDirectorAutocompletePage + 1 <= salesDirector.autocompleteListPagination.totalPages) {
            dispatchAction(getSalesDirectorList({
                page: createSalesDirectorAutocompletePage + 1,
                search: createSalesDirectorAutocompleteInput,
                addToCurrent: true,
                branch_id: 0
            }));

            setCreateSalesDirectorAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCreateSalesDirectorAutocompleteInputChange = (search: string) => {

        setCreateSalesDirectorAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateSalesDirectorAutocompletePage(1);
            dispatchAction(emptySalesDirectorList());
            return;
        }

        const searchingFound = salesDirector.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createSalesDirectorAutocompleteSearchId);

        setCreateSalesDirectorAutocompleteSearchId(setTimeout(() => createSalesDirectorAutocompleteSearch(search), 300))
    }

    const createSalesDirectorAutocompleteSearch = (search: string) => {
        setCreateSalesDirectorAutocompletePage(1);
        dispatchAction(getSalesDirectorList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // UnitManager
    const [createUnitManagerAutocompleteSelected, setCreateUnitManagerAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createUnitManagerAutocompleteSearchId, setCreateUnitManagerAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createUnitManagerAutocompleteInput, setCreateUnitManagerAutocompleteInput] = useState('');
    const [createUnitManagerAutocompletePage, setCreateUnitManagerAutocompletePage] = useState(1);

    const createUnitManagerAutocompleteNextPage = () => {
        if (createUnitManagerAutocompletePage + 1 <= unitManager.autocompleteListPagination.totalPages) {
            dispatchAction(getUnitManagerList({
                page: createUnitManagerAutocompletePage + 1,
                search: createUnitManagerAutocompleteInput,
                addToCurrent: true,
                branch_id: 0
            }));

            setCreateUnitManagerAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCreateUnitManagerAutocompleteInputChange = (search: string) => {

        setCreateUnitManagerAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateUnitManagerAutocompletePage(1);
            dispatchAction(emptyUnitManagerList());
            return;
        }

        const searchingFound = unitManager.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createUnitManagerAutocompleteSearchId);

        setCreateUnitManagerAutocompleteSearchId(setTimeout(() => createUnitManagerAutocompleteSearch(search), 300))
    }

    const createUnitManagerAutocompleteSearch = (search: string) => {
        setCreateUnitManagerAutocompletePage(1);
        dispatchAction(getUnitManagerList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    const openCreateModal = () => {
        setCreateClientAutocompletePage(1);
        dispatchAction(emptyClientList());
        setCreateAgentAutocompletePage(1);
        dispatchAction(emptyAgentList());
        setIsCreateModalOpen(true);
    }

    const createClickEvent = () => {
        if (
            !(createClientAutocompleteSelected.value && createClientAutocompleteSelected.value > 0)
            || !(createAgentAutocompleteSelected.value && createAgentAutocompleteSelected.value > 0)
            || !(createSalesDirectorAutocompleteSelected.value && createSalesDirectorAutocompleteSelected.value > 0)
            || !(createUnitManagerAutocompleteSelected.value && createUnitManagerAutocompleteSelected.value > 0)
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        if (cjPercent + salesDirectorPercent + unitManagerPercent + agentPercent !== 20) {
            showWarningToast('Percentage must be a total of 20%');
            return;
        }

        dispatchAction(create({
            lot_id,
            client_id: createClientAutocompleteSelected.value,
            agent_user_id: createAgentAutocompleteSelected.value,
            sales_director_user_id: createSalesDirectorAutocompleteSelected.value,
            unit_manager_user_id: createUnitManagerAutocompleteSelected.value,
            cj_percent: cjPercent,
            sales_director_percent: salesDirectorPercent,
            unit_manager_percent: unitManagerPercent,
            agent_percent: agentPercent
        }));
    }

    // useEffect(() => {
    //     if (lotClient.isCreateSuccess) {
    //         setIsCreateModalOpen(false);
    //         setCreateClientAutocompleteSelected({
    //             value: 0,
    //             label: ''
    //         });
    //         setCreateAgentAutocompleteSelected({
    //             value: 0,
    //             label: ''
    //         });
    //         setCreateSalesDirectorAutocompleteSelected({
    //             value: 0,
    //             label: ''
    //         });
    //         setCreateUnitManagerAutocompleteSelected({
    //             value: 0,
    //             label: ''
    //         });
    //     }

    // }, [lotClient.isCreateSuccess]);

    useEffect(() => {
        if (lotClient.isCreateSuccess) {
            setIsCreateModalOpen(false);
        }

    }, [lotClient.isCreateSuccess]);

    // Terminate
    const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);
    const terminateModalHandleClose = () => setIsTerminateModalOpen(false);
    const [terminateData, setTerminateData] = useState<ITerminate>({
        lot_id: 0,
        terminate_note: '',
        lot_block: 0,
        lot_lot: 0,
        is_permanently_delete: false
    });

    const openTerminateModal = (data: ILotClientListItem) => {
        setTerminateData({ ...data, is_permanently_delete: false });
        setIsTerminateModalOpen(true);
    }

    const terminateClickEvent = () => {
        if (terminateData && terminateData.lot_id)
            dispatchAction(terminate(terminateData));
    }

    useEffect(() => {
        if (!clientLot.isTerminateLoading)
            setIsTerminateModalOpen(false);

    }, [clientLot.isTerminateLoading]);

    useEffect(() => {
        if (clientLot.isRefetchList) {
            dispatchAction(getSingle(lot_id));
            dispatchAction(getList({
                page: page,
                lot_id
            }));
        }

    }, [clientLot.isRefetchList]);

    return (
        <>
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ShoppingCartIcon />}
                    onClick={openCreateModal}
                    disabled={!is_available}
                >
                    Buy
                </Button>
                : null}
            <Modal
                open={isCreateModalOpen}
                className="lot-clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lot-clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Buy Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lot-clients-modal-form-controls"
                    >
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        createClientAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={client.isAutocompleteListLoading}
                            options={client.autocompleteList}
                            value={createClientAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setCreateClientAutocompleteSelected(newValue)
                            }}
                            inputValue={createClientAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateClientAutocompleteInput(newInputValue);
                                onCreateClientAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Client" />}
                            disabled={lotClient.isCreateLoading}
                        />
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        createSalesDirectorAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={salesDirector.isAutocompleteListLoading}
                            options={salesDirector.autocompleteList}
                            value={createSalesDirectorAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setCreateSalesDirectorAutocompleteSelected(newValue)
                            }}
                            inputValue={createSalesDirectorAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateSalesDirectorAutocompleteInput(newInputValue);
                                onCreateSalesDirectorAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Sales Director" />}
                            disabled={lotClient.isCreateLoading}
                        />
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        createUnitManagerAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={unitManager.isAutocompleteListLoading}
                            options={unitManager.autocompleteList}
                            value={createUnitManagerAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setCreateUnitManagerAutocompleteSelected(newValue)
                            }}
                            inputValue={createUnitManagerAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateUnitManagerAutocompleteInput(newInputValue);
                                onCreateUnitManagerAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Unit Manager" />}
                            disabled={lotClient.isCreateLoading}
                        />
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        createAgentAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={agent.isAutocompleteListLoading}
                            options={agent.autocompleteList}
                            value={createAgentAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setCreateAgentAutocompleteSelected(newValue)
                            }}
                            inputValue={createAgentAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateAgentAutocompleteInput(newInputValue);
                                onCreateAgentAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Agent" />}
                            disabled={lotClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Conde %"
                            variant="outlined"
                            fullWidth
                            required
                            value={cjPercent}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setCjPercent(Number(e.target.value))
                                }
                            }}
                            disabled={lotClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Sales Director %"
                            variant="outlined"
                            fullWidth
                            required
                            value={salesDirectorPercent}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setSalesDirectorPercent(Number(e.target.value))
                                }
                            }}
                            disabled={lotClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Unit Manager %"
                            variant="outlined"
                            fullWidth
                            required
                            value={unitManagerPercent}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setUnitManagerPercent(Number(e.target.value))
                                }
                            }}
                            disabled={lotClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Agent %"
                            variant="outlined"
                            fullWidth
                            required
                            value={agentPercent}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setAgentPercent(Number(e.target.value))
                                }
                            }}
                            disabled={lotClient.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={lotClient.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: lotClient.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={lotClient.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="lot-clients-table"
            >
                {lotClient.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Price Per Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Director</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Manager</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Count (Months)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Monthly Amortization</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Delay (Days)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Terminate Note</TableCell>
                                {getRole() === 1 ?
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                                    : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lotClient.list && lotClient.list.length > 0
                                ?
                                lotClient.list.map(item => {
                                    return (
                                        <TableRow key={item.client_lot_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/areas/${item.area_id}`)}
                                                >
                                                    {item.area_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/areas/${item.area_id}}/lot/${item.lot_id}`)}
                                                >
                                                    Block {item.lot_block} Lot {item.lot_lot}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_square_meters}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(Number(item.lot_price_per_square_meters))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/sales-directors/${item.sales_director_id}`)}
                                                >
                                                    {item.sales_director_first_name} {item.sales_director_middle_name} {item.sales_director_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/unit-managers/${item.unit_manager_id}`)}
                                                >
                                                    {item.unit_manager_first_name} {item.unit_manager_middle_name} {item.unit_manager_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/agents/${item.agent_id}`)}
                                                >
                                                    {item.agent_first_name} {item.agent_middle_name} {item.agent_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_count} / {item.lot_months_to_pay}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count)))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{(item.delayed_days > 0 ? item.delayed_days : 0)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_date).format('MMMM D, YYYY hh:mm A') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_due_date ? dayjs(item.last_payment_due_date).format('MMMM D, YYYY') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.sold_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={dayjs(item.due_date).format('MMMM D, YYYY')} variant="outlined" color={item.delayed_days > 0 ? 'error' : 'primary'} />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {Number(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count))) === 0 ? <Chip label='Paid' color="primary" /> :
                                                    <Chip label={item.is_terminated ? 'Terminated' : 'Active'} color={item.is_terminated ? 'error' : 'success'} />
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.terminate_note ? item.terminate_note : 'N/A'}
                                            </TableCell>
                                            {getRole() === 1 ?
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                    <Button disabled={item.is_terminated} onClick={() => openTerminateModal(item)} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                                                        Terminate
                                                    </Button>
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!lotClient.isListLoading && lotClient.listPagination && lotClient.listPagination.total > 0 ?
                <Box
                    className="lot-clients-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {lotClient.listPagination.fromData} - {lotClient.listPagination.toData} out of {lotClient.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={lotClient.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isTerminateModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Terminate Contract
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to terminate client lot 'Block {terminateData?.lot_block} Lot {terminateData?.lot_lot}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={terminateData.terminate_note}
                            onChange={(e) => {
                                setTerminateData((previousVal: ITerminate) => {
                                    return { ...previousVal, terminate_note: e.target.value };
                                })
                            }}
                            disabled={clientLot.isTerminateLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={terminateData.is_permanently_delete}
                                    onChange={(e) => {
                                        setTerminateData((previousVal: ITerminate) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={terminateClickEvent}
                            disabled={clientLot.isTerminateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientLot.isTerminateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={terminateModalHandleClose}
                            disabled={clientLot.isTerminateLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default LotClients;