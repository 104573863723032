import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ILotPaymentListItem } from '../../types/lot-payment/list-item';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';

interface IListParams {
    lot_id: number,
    page: number
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/lot/${queryParams.lot_id}/payment`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'lot-payment/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);


export const lotClient = createSlice({
    name: 'lot-payment',
    initialState: {

        // List
        list: [] as ILotPaymentListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
            })
    }
});

export default lotClient.reducer;