import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import Lots from '../lots';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { _delete, getSingle, getSingleForEdit, update } from '../../../../store/reducers/area';
import { IAreaEdit } from '../../../../types/area/edit';
import { showWarningToast } from '../../../../toast/warn';
import { IArea } from '../../../../types/area/single';
import toFixed from '../../../../utils/to-fixed';
import toMoney from '../../../../utils/to-money';
import getRole from '../../../../utils/get-role';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SingleArea: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const area = useSelector((state: RootState) => state.area);
    const lot = useSelector((state: RootState) => state.lot);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingle(parseInt(id)));
    }, []);

    useEffect(() => {
        if (area.isRefetchList && id)
            dispatchAction(getSingle(parseInt(id)));

    }, [area.isRefetchList]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: ''
    }
    const [editData, setEditData] = useState<IAreaEdit>(editDataPlaceholder);

    const getEditData = () => {
        if (!id)
            return;

        dispatchAction(getSingleForEdit(parseInt(id)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (area.isEditGetSingleSuccess)
            setEditData(area.editSingleData);

    }, [area.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (area.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [area.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IArea>();

    const openDeleteModal = () => {

        if (!area.singleData)
            return;

        setDeleteData(area.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!area.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [area.isDeleteLoading]);

    useEffect(() => {

        if (area.isDeleteSuccess)
            navigate('/areas')

    }, [area.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/areas')}
                    >
                        Areas
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {area.singleData.name}
                    </Link>
                </Breadcrumbs>
            </Box>
            {getRole() === 1 || getRole() === 2 ?
                <>
                    <Box
                        className="area-info-container"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info-edit-button"
                        >
                            {getRole() === 1 ?
                                <>
                                    <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                                        Delete
                                    </Button>
                                </>
                                : null}
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Typography
                                sx={{
                                    fontWeight: '700'
                                }}
                            >
                                Name
                            </Typography>
                            <Typography>
                                {area.singleData.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Typography
                                sx={{
                                    fontWeight: '700',
                                    marginRight: '5px'
                                }}
                            >
                                Area Worth
                            </Typography>
                            <Typography>
                                {toMoney(area.singleData.area_worth)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        marginRight: '5px'
                                    }}
                                >
                                    Lots Sold
                                </Typography>
                                <Typography
                                    fontSize="small"
                                    color="gray"
                                >
                                    ({toFixed(Number(area.singleData.sold_lots_count / area.singleData.lots_count * 100), 2)})
                                </Typography>
                            </Box>
                            <Typography>
                                {area.singleData.sold_lots_count} / {area.singleData.lots_count}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        marginRight: '5px'
                                    }}
                                >
                                    Sold Lots Worth
                                </Typography>
                                <Typography
                                    fontSize="small"
                                    color="gray"
                                >
                                    ({toFixed(Number(area.singleData.sold_lots_worth / area.singleData.area_worth * 100), 2)}% of area worth)
                                </Typography>
                            </Box>
                            <Typography>
                                {toMoney(area.singleData.sold_lots_worth)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        marginRight: '5px'
                                    }}
                                    color="gray"
                                >
                                    Available Lots Total Worth
                                </Typography>
                                <Typography
                                    fontSize="small"
                                    color="gray"
                                >
                                    ({toFixed(Number((area.singleData.area_worth - area.singleData.sold_lots_worth) / area.singleData.area_worth * 100), 2)}% of area worth)
                                </Typography>
                            </Box>
                            <Typography>
                                {toMoney(area.singleData.area_worth - area.singleData.sold_lots_worth)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Typography
                                sx={{
                                    fontWeight: '700'
                                }}
                            >
                                Terminated Sales
                            </Typography>
                            <Typography>
                                {toMoney(area.singleData.terminated_sales)}
                            </Typography>
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        marginRight: '5px'
                                    }}
                                    color="primary"
                                >
                                    Total Sales
                                </Typography>
                                <Typography
                                    fontSize="small"
                                    color="gray"
                                >
                                    ({toFixed(Number(area.singleData.total_sales / area.singleData.sold_lots_worth * 100), 2)}% of Sold Lots Worth)
                                </Typography>
                            </Box>
                            <Typography>
                                {toMoney(area.singleData.total_sales)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        marginRight: '5px'
                                    }}
                                    color="error"
                                >
                                    Total Balance
                                </Typography>
                                <Typography
                                    fontSize="small"
                                    color="gray"
                                >
                                    ({toFixed(Number((area.singleData.sold_lots_worth - area.singleData.total_sales) / area.singleData.sold_lots_worth * 100), 2)}% of Sold Lots Worth)
                                </Typography>
                            </Box>
                            <Typography>
                                {toMoney(area.singleData.sold_lots_worth - area.singleData.total_sales)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="area-info-container"
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <Box
                            className="area-info"
                        >
                            <Typography
                                sx={{
                                    fontWeight: '700'
                                }}
                                color="primary"
                            >
                                Overall Total Sales
                            </Typography>
                            <Typography>
                                {toMoney(Number(area.singleData.total_sales) + Number(area.singleData.terminated_sales))}
                            </Typography>
                        </Box>
                    </Box>
                </>
                : null}
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Lots (${lot.listPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Lots area_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="areas-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="areas-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Area
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: area.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Area name"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IAreaEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            value={editData.name}
                            disabled={area.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="success"
                            onClick={editClickEvent}
                            disabled={area.isUpdateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: area.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="areas-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="areas-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Area
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete area '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={area.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: area.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={area.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleArea;