import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ILotListItem } from '../../../../types/lot/list-item';
import { ILotCreate } from '../../../../types/lot/create';
import { ILotEdit } from '../../../../types/lot/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/lot';
import { getSingle as getSingleArea } from '../../../../store/reducers/area';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import isWholeNumber from '../../../../utils/is-whole-number';
import getRole from '../../../../utils/get-role';
import toFixed from '../../../../utils/to-fixed';
import addOne from '../../../../utils/add-one';

interface IProps {
    area_id: number
}

const Lots: React.FunctionComponent<IProps> = ({ area_id }) => {
    // Global
    const navigate = useNavigate();
    const _lot = useSelector((state: RootState) => state.lot);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [block, setBlock] = useState('');
    const [lot, setLot] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            area_id: area_id,
            page: value,
            block: block,
            lot: lot
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                area_id: area_id,
                page: page,
                block: block,
                lot: lot
            }));
    }, []);

    useEffect(() => {
        if (_lot.isRefetchList)
            dispatchAction(getList({
                area_id: area_id,
                page: page,
                block: block,
                lot: lot
            }));

    }, [_lot.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            area_id: area_id,
            page: 1,
            block: block,
            lot: lot
        }));
    }

    const blockHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (isWholeNumber(e.target.value)) {
            setBlock(e.target.value);
        }
    }

    const lotHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (isWholeNumber(e.target.value)) {
            setLot(e.target.value);
        }
    }

    // Create
    const createDataPlaceholder = {
        area_id: area_id,
        lot: '',
        block: '',
        square_meters: '',
        price_per_month: '',
        months_to_pay: '144',
        total_contract_price: '',
        price_per_square_meters: ''
    }
    const [createData, setCreateData] = useState<ILotCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleOpen = () => setIsCreateModalOpen(true);
    const createModalHandleClose = () => {
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false)
    }

    const createClickEvent = () => {
        if (
            !createData.lot
            || !createData.block
            || !createData.square_meters
            || !createData.price_per_month
            || !createData.months_to_pay
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create(createData));
    }

    useEffect(() => {
        if (_lot.isCreateSuccess) {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);
            dispatchAction(getSingleArea(area_id));
        }

    }, [_lot.isCreateSuccess]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        area_id: area_id,
        lot: '',
        block: '',
        square_meters: '',
        price_per_month: '',
        months_to_pay: '',
        total_contract_price: '',
        price_per_square_meters: ''
    }
    const [editData, setEditData] = useState<ILotEdit>(editDataPlaceholder);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (_lot.isEditGetSingleSuccess) {
            setEditData(_lot.editSingleData);
        }

    }, [_lot.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.lot
            || !editData.block
            || !editData.square_meters
            || !editData.price_per_month
            || !editData.months_to_pay
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (_lot.isUpdateSuccess) {
            setIsEditModalOpen(false);
            dispatchAction(getSingleArea(area_id));
        }

    }, [_lot.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<ILotListItem>();

    const openDeleteModal = (data: ILotListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.lot_id)
            dispatchAction(_delete(deleteData.lot_id));
    }

    useEffect(() => {
        if (!_lot.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [_lot.isDeleteLoading]);

    useEffect(() => {

        if (_lot.isDeleteSuccess)
            dispatchAction(getSingleArea(area_id));

    }, [_lot.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Lots
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="lots-search-container"
            >
                <TextField
                    sx={{
                        display: 'block',
                        marginBottom: '10px'
                    }}
                    label="Block"
                    variant="outlined"
                    fullWidth
                    value={block}
                    onChange={blockHandleChange}
                />
                <TextField
                    sx={{
                        display: 'block',
                        marginBottom: '10px'
                    }}
                    label="Lot"
                    variant="outlined"
                    fullWidth
                    value={lot}
                    onChange={lotHandleChange}
                />
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<SearchIcon />}
                    onClick={searchList}
                >
                    Search
                </Button>
            </Box>
            <Divider
                sx={{
                    marginBottom: '20px'
                }}
            />
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createModalHandleOpen}
                >
                    Create New
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="lots-table"
            >
                {_lot.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Available</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Block</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Square meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Price Per Square meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Monthly Amortization</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Months To Pay</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Contract Price</TableCell>
                                {getRole() === 1 || getRole() === 2 ?
                                    <>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Director</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Manager</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Count (Months)</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Delay (Days)</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Date</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Due Date</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold Date</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                                    </>
                                    : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_lot.list && _lot.list.length > 0
                                ?
                                _lot.list.map(item => {
                                    return (
                                        <TableRow key={item.lot_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={item.sold_date ? 'No' : 'Yes'} color={item.sold_date ? 'error' : 'success'} />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_block}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_lot}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_square_meters}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(Number(item.lot_price_per_square_meters))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_months_to_pay}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.lot_price_per_square_meters * item.lot_square_meters)}</TableCell>
                                            {getRole() === 1 || getRole() === 2 ?
                                                <>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        {item.sold_date ?
                                                            <Link
                                                                underline="hover"
                                                                sx={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => navigate(`/clients/${item.client_id}`)}
                                                            >
                                                                {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                            </Link>
                                                            : 'N/A'}
                                                    </TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        <Link
                                                            underline="hover"
                                                            sx={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => navigate(`/sales-directors/${item.sales_director_id}`)}
                                                        >
                                                            {item.sales_director_first_name} {item.sales_director_middle_name} {item.sales_director_last_name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        <Link
                                                            underline="hover"
                                                            sx={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => navigate(`/unit-managers/${item.unit_manager_id}`)}
                                                        >
                                                            {item.unit_manager_first_name} {item.unit_manager_middle_name} {item.unit_manager_last_name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        {item.sold_date ?
                                                            <Link
                                                                underline="hover"
                                                                sx={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => navigate(`/agents/${item.agent_id}`)}
                                                            >
                                                                {item.agent_first_name} {item.agent_middle_name} {item.agent_last_name}
                                                            </Link>
                                                            : 'N/A'}
                                                    </TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sold_date ? `${item.payment_count} / ${item.lot_months_to_pay}` : 'N/A'}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sold_date ? toMoney(item.total_payed) : 'N/A'}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sold_date ? toMoney(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count))) : 'N/A'}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sold_date ? (item.delayed_days > 0 ? item.delayed_days : 0) : 'N/A'}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_date).format('MMMM D, YYYY hh:mm A') : `N/A`}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_due_date ? dayjs(item.last_payment_due_date).format('MMMM D, YYYY') : `N/A`}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sold_date ? dayjs(item.sold_date).format('MMMM D, YYYY hh:mm A') : `N/A`}</TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        {item.due_date ?
                                                            <Chip label={dayjs(item.due_date).format('MMMM D, YYYY')} variant="outlined" color={item.delayed_days > 0 ? 'error' : 'primary'} />
                                                            : `N/A`}
                                                    </TableCell>
                                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <Button onClick={() => navigate(`/areas/${area_id}/lot/${item.lot_id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                                View
                                                            </Button>
                                                            {getRole() === 1 ?
                                                                <>
                                                                    <Button disabled={!!item.sold_date} onClick={() => getEditData(item.lot_id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                                        Edit
                                                                    </Button>
                                                                    <Button disabled={!!item.sold_date} onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                                : null}
                                                        </Stack>
                                                    </TableCell>
                                                </>
                                                : null}
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!_lot.isListLoading && _lot.listPagination && _lot.listPagination.total > 0 ?
                <Box
                    className="lots-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {_lot.listPagination.fromData} - {_lot.listPagination.toData} out of {_lot.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={_lot.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}

            <Modal
                open={isCreateModalOpen}
                className="lots-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lots-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lots-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Block"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.block}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setCreateData((previousVal: ILotCreate) => {
                                        return { ...previousVal, block: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Lot"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.lot}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setCreateData((previousVal: ILotCreate) => {
                                        return { ...previousVal, lot: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Square meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.square_meters}
                            onChange={(e) => {
                                setCreateData((previousVal: ILotCreate) => {
                                    return { ...previousVal, square_meters: e.target.value };
                                })
                            }}
                            disabled={_lot.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Price Per Square Meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.price_per_square_meters}
                            onChange={(e) => {
                                setCreateData((previousVal: ILotCreate) => {
                                    return {
                                        ...previousVal, ...{
                                            total_contract_price: (Number(previousVal.square_meters) * Number(e.target.value)).toString(),
                                            price_per_month: ((Number(previousVal.square_meters) * Number(e.target.value)) / Number(previousVal.months_to_pay)).toString(),
                                            price_per_square_meters: e.target.value
                                        }
                                    };
                                })
                            }}
                            disabled={_lot.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Total Contract Price"
                            variant="outlined"
                            fullWidth
                            value={createData.total_contract_price}
                            disabled={true}
                        />
                        <TextField
                            InputLabelProps={!!createData.price_per_month ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Monthly Amortization"
                            variant="outlined"
                            fullWidth
                            value={createData.price_per_month}
                            disabled={true}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Months to pay"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.months_to_pay}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setCreateData((previousVal: ILotCreate) => {
                                        return { ...previousVal, months_to_pay: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={_lot.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _lot.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={_lot.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isEditModalOpen}
                className="lots-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lots-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lots-modal-form-controls"
                    >
                        <TextField
                            InputLabelProps={!!editData.block ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Block"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.block}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, block: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.lot ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Lot"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.lot}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, lot: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.square_meters ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Square meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.square_meters}
                            onChange={(e) => {
                                setEditData((previousVal: ILotEdit) => {
                                    return { ...previousVal, square_meters: e.target.value };
                                })
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.price_per_square_meters ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Price Per Square Meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.price_per_square_meters}
                            onChange={(e) => {
                                setEditData((previousVal: ILotEdit) => {
                                    return {
                                        ...previousVal, ...{
                                            total_contract_price: (Number(previousVal.square_meters) * Number(e.target.value)).toString(),
                                            price_per_month: ((Number(previousVal.square_meters) * Number(e.target.value)) / Number(previousVal.months_to_pay)).toString(),
                                            price_per_square_meters: e.target.value
                                        }
                                    };
                                })
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.total_contract_price ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Total Contract Price"
                            variant="outlined"
                            fullWidth
                            value={editData.total_contract_price}
                            disabled={true}
                        />
                        <TextField
                            InputLabelProps={!!editData.price_per_month ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Monthly Amortization"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.price_per_month}
                            disabled={true}
                        />
                        <TextField
                            InputLabelProps={!!editData.months_to_pay ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Months to pay"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.months_to_pay}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, months_to_pay: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={_lot.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _lot.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={_lot.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="lots-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lots-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lots-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete lot 'Block {deleteData?.lot_block} Lot {deleteData?.lot_lot}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={_lot.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _lot.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={_lot.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default Lots;