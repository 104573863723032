export default (branch_id:number) => {

    const accountDetails = localStorage.getItem('ACCOUNT_DETAILS');

    if(!accountDetails)
        return false;

    const data = JSON.parse(accountDetails);

    return data.branch_id === branch_id;
}