import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { AppDispatch, RootState } from '../../../../store/store';
import { AsyncThunkAction, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { IAutocomplete } from '../../../../types/autocomplete';
import { emptyAutocompleteList as emptyPaymentOptionAutocompleteList, getAutocompleteList as getPaymentOptionAutocompleteList } from '../../../../store/reducers/payment-option';
import { emptyAutocompleteList as emptyBranchAutocompleteList, getAutocompleteList as getBranchAutocompleteList } from '../../../../store/reducers/branch';
import { emptyAutocompleteList as emptyClientAutocompleteList, getAutocompleteList as getClientAutocompleteList } from '../../../../store/reducers/client';
import { emptyAutocompleteList as emptyCashierAutocompleteList, getAutocompleteList as getCashierAutocompleteList } from '../../../../store/reducers/cashier';
import { emptyAutocompleteList as emptyAgentAutocompleteList, getAutocompleteList as getAgentAutocompleteList } from '../../../../store/reducers/agent';
import { emptyAutocompleteList as emptySalesDirectorAutocompleteList, getAutocompleteList as getSalesDirectorAutocompleteList } from '../../../../store/reducers/sales-director';
import { emptyAutocompleteList as emptyUnitManagerAutocompleteList, getAutocompleteList as getUnitManagerAutocompleteList } from '../../../../store/reducers/unit-manager';
import { emptyAutocompleteList as emptyAreaAutocompleteList, getAutocompleteList as getAreaAutocompleteList } from '../../../../store/reducers/area';
import { showWarningToast } from '../../../../toast/warn';
import { getList } from '../../../../store/reducers/monthly-report';
import toMoney from '../../../../utils/to-money';
import toFixed from '../../../../utils/to-fixed';
import { MonthPicker } from '@mui/x-date-pickers';

const MonthlyReport: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatchAction = useDispatch<AppDispatch>();
    const [page, setPage] = useState(1);
    const monthlyReport = useSelector((state: RootState) => state.monthlyReport);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const [fromDateValue, setFromDateValue] = useState<Dayjs | null>(null);
    const [toDateValue, setToDateValue] = useState<Dayjs | null>(null);

    // Payment option autocomplete
    const paymentOption = useSelector((state: RootState) => state.paymentOption);
    const [paymentOptionAutocompleteSelected, setPaymentOptionAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [paymentOptionAutocompleteSearchId, setPaymentOptionAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [paymentOptionAutocompleteInput, setPaymentOptionAutocompleteInput] = useState('');
    const [paymentOptionAutocompletePage, setPaymentOptionAutocompletePage] = useState(1);

    const paymentOptionAutocompleteNextPage = () => {
        if (paymentOptionAutocompletePage + 1 <= paymentOption.autocompleteListPagination.totalPages) {
            dispatchAction(getPaymentOptionAutocompleteList({
                page: paymentOptionAutocompletePage + 1,
                search: paymentOptionAutocompleteInput,
                addToCurrent: true,
            }));

            setPaymentOptionAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onPaymentOptionAutocompleteInputChange = (search: string) => {

        setPaymentOptionAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setPaymentOptionAutocompletePage(1);
            dispatchAction(emptyPaymentOptionAutocompleteList());
            return;
        }

        const searchingFound = paymentOption.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(paymentOptionAutocompleteSearchId);

        setPaymentOptionAutocompleteSearchId(setTimeout(() => paymentOptionAutocompleteSearch(search), 300))
    }

    const paymentOptionAutocompleteSearch = (search: string) => {
        setPaymentOptionAutocompletePage(1);
        dispatchAction(getPaymentOptionAutocompleteList({
            page: 1,
            search: search
        }));
    }

    // branch autocomplete
    const branch = useSelector((state: RootState) => state.branch);
    const [branchAutocompleteSelected, setBranchAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [branchAutocompleteSearchId, setBranchAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [branchAutocompleteInput, setBranchAutocompleteInput] = useState('');
    const [branchAutocompletePage, setBranchAutocompletePage] = useState(1);

    const branchAutocompleteNextPage = () => {
        if (branchAutocompletePage + 1 <= branch.autocompleteListPagination.totalPages) {
            dispatchAction(getBranchAutocompleteList({
                page: branchAutocompletePage + 1,
                search: branchAutocompleteInput,
                addToCurrent: true,
            }));

            setBranchAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onBranchAutocompleteInputChange = (search: string) => {

        setBranchAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setBranchAutocompletePage(1);
            dispatchAction(emptyBranchAutocompleteList());
            return;
        }

        const searchingFound = branch.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(branchAutocompleteSearchId);

        setBranchAutocompleteSearchId(setTimeout(() => branchAutocompleteSearch(search), 300))
    }

    const branchAutocompleteSearch = (search: string) => {
        setBranchAutocompletePage(1);
        dispatchAction(getBranchAutocompleteList({
            page: 1,
            search: search
        }));
    }

    // Area autocomplete
    const area = useSelector((state: RootState) => state.area);
    const [areaAutocompleteSelected, setAreaAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [areaAutocompleteSearchId, setAreaAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [areaAutocompleteInput, setAreaAutocompleteInput] = useState('');
    const [areaAutocompletePage, setAreaAutocompletePage] = useState(1);

    const areaAutocompleteNextPage = () => {
        if (areaAutocompletePage + 1 <= area.autocompleteListPagination.totalPages) {
            dispatchAction(getAreaAutocompleteList({
                page: areaAutocompletePage + 1,
                search: areaAutocompleteInput,
                addToCurrent: true,
            }));

            setAreaAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onAreaAutocompleteInputChange = (search: string) => {

        setAreaAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setAreaAutocompletePage(1);
            dispatchAction(emptyAreaAutocompleteList());
            return;
        }

        const searchingFound = area.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(areaAutocompleteSearchId);

        setAreaAutocompleteSearchId(setTimeout(() => areaAutocompleteSearch(search), 300))
    }

    const areaAutocompleteSearch = (search: string) => {
        setAreaAutocompletePage(1);
        dispatchAction(getAreaAutocompleteList({
            page: 1,
            search: search
        }));
    }

    // Client autocomplete
    const client = useSelector((state: RootState) => state.client);
    const [clientAutocompleteSelected, setClientAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [clientAutocompleteSearchId, setClientAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [clientAutocompleteInput, setClientAutocompleteInput] = useState('');
    const [clientAutocompletePage, setClientAutocompletePage] = useState(1);

    const clientAutocompleteNextPage = () => {
        if (clientAutocompletePage + 1 <= client.autocompleteListPagination.totalPages) {
            dispatchAction(getClientAutocompleteList({
                page: clientAutocompletePage + 1,
                search: clientAutocompleteInput,
                branch_id: 0,
                addToCurrent: true,
            }));

            setClientAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onClientAutocompleteInputChange = (search: string) => {

        setClientAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setClientAutocompletePage(1);
            dispatchAction(emptyClientAutocompleteList());
            return;
        }

        const searchingFound = client.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(clientAutocompleteSearchId);

        setClientAutocompleteSearchId(setTimeout(() => clientAutocompleteSearch(search), 300))
    }

    const clientAutocompleteSearch = (search: string) => {
        setClientAutocompletePage(1);
        dispatchAction(getClientAutocompleteList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // Cashier autocomplete
    const cashier = useSelector((state: RootState) => state.cashier);
    const [cashierAutocompleteSelected, setCashierAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [cashierAutocompleteSearchId, setCashierAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [cashierAutocompleteInput, setCashierAutocompleteInput] = useState('');
    const [cashierAutocompletePage, setCashierAutocompletePage] = useState(1);

    const cashierAutocompleteNextPage = () => {
        if (cashierAutocompletePage + 1 <= cashier.autocompleteListPagination.totalPages) {
            dispatchAction(getCashierAutocompleteList({
                page: cashierAutocompletePage + 1,
                search: cashierAutocompleteInput,
                branch_id: 0,
                addToCurrent: true,
            }));

            setCashierAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCashierAutocompleteInputChange = (search: string) => {

        setCashierAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCashierAutocompletePage(1);
            dispatchAction(emptyCashierAutocompleteList());
            return;
        }

        const searchingFound = cashier.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(cashierAutocompleteSearchId);

        setCashierAutocompleteSearchId(setTimeout(() => cashierAutocompleteSearch(search), 300))
    }

    const cashierAutocompleteSearch = (search: string) => {
        setCashierAutocompletePage(1);
        dispatchAction(getCashierAutocompleteList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // Agent autocomplete
    const agent = useSelector((state: RootState) => state.agent);
    const [agentAutocompleteSelected, setAgentAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [agentAutocompleteSearchId, setAgentAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [agentAutocompleteInput, setAgentAutocompleteInput] = useState('');
    const [agentAutocompletePage, setAgentAutocompletePage] = useState(1);

    const agentAutocompleteNextPage = () => {
        if (agentAutocompletePage + 1 <= agent.autocompleteListPagination.totalPages) {
            dispatchAction(getAgentAutocompleteList({
                page: agentAutocompletePage + 1,
                search: agentAutocompleteInput,
                branch_id: 0,
                addToCurrent: true,
            }));

            setAgentAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onAgentAutocompleteInputChange = (search: string) => {

        setAgentAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setAgentAutocompletePage(1);
            dispatchAction(emptyAgentAutocompleteList());
            return;
        }

        const searchingFound = agent.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(agentAutocompleteSearchId);

        setAgentAutocompleteSearchId(setTimeout(() => agentAutocompleteSearch(search), 300))
    }

    const agentAutocompleteSearch = (search: string) => {
        setAgentAutocompletePage(1);
        dispatchAction(getAgentAutocompleteList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // Sales Director autocomplete
    const salesDirector = useSelector((state: RootState) => state.salesDirector);
    const [salesDirectorAutocompleteSelected, setSalesDirectorAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [salesDirectorAutocompleteSearchId, setSalesDirectorAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [salesDirectorAutocompleteInput, setSalesDirectorAutocompleteInput] = useState('');
    const [salesDirectorAutocompletePage, setSalesDirectorAutocompletePage] = useState(1);

    const salesDirectorAutocompleteNextPage = () => {
        if (salesDirectorAutocompletePage + 1 <= salesDirector.autocompleteListPagination.totalPages) {
            dispatchAction(getSalesDirectorAutocompleteList({
                page: salesDirectorAutocompletePage + 1,
                search: salesDirectorAutocompleteInput,
                branch_id: 0,
                addToCurrent: true,
            }));

            setSalesDirectorAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onSalesDirectorAutocompleteInputChange = (search: string) => {

        setSalesDirectorAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setSalesDirectorAutocompletePage(1);
            dispatchAction(emptySalesDirectorAutocompleteList());
            return;
        }

        const searchingFound = salesDirector.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(salesDirectorAutocompleteSearchId);

        setSalesDirectorAutocompleteSearchId(setTimeout(() => salesDirectorAutocompleteSearch(search), 300))
    }

    const salesDirectorAutocompleteSearch = (search: string) => {
        setSalesDirectorAutocompletePage(1);
        dispatchAction(getSalesDirectorAutocompleteList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    // Unit Manager autocomplete
    const unitManager = useSelector((state: RootState) => state.unitManager);
    const [unitManagerAutocompleteSelected, setUnitManagerAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [unitManagerAutocompleteSearchId, setUnitManagerAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [unitManagerAutocompleteInput, setUnitManagerAutocompleteInput] = useState('');
    const [unitManagerAutocompletePage, setUnitManagerAutocompletePage] = useState(1);

    const unitManagerAutocompleteNextPage = () => {
        if (unitManagerAutocompletePage + 1 <= unitManager.autocompleteListPagination.totalPages) {
            dispatchAction(getUnitManagerAutocompleteList({
                page: unitManagerAutocompletePage + 1,
                search: unitManagerAutocompleteInput,
                branch_id: 0,
                addToCurrent: true,
            }));

            setUnitManagerAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onUnitManagerAutocompleteInputChange = (search: string) => {

        setUnitManagerAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setUnitManagerAutocompletePage(1);
            dispatchAction(emptyUnitManagerAutocompleteList());
            return;
        }

        const searchingFound = unitManager.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(unitManagerAutocompleteSearchId);

        setUnitManagerAutocompleteSearchId(setTimeout(() => unitManagerAutocompleteSearch(search), 300))
    }

    const unitManagerAutocompleteSearch = (search: string) => {
        setUnitManagerAutocompletePage(1);
        dispatchAction(getUnitManagerAutocompleteList({
            page: 1,
            search: search,
            branch_id: 0
        }));
    }

    const showReport = () => {

        if (!fromDateValue || !toDateValue) {
            showWarningToast('Please select "Date from" and "Date to"');
            return;
        }

        if (fromDateValue.isAfter(toDateValue)) {
            showWarningToast('"Date to" is earlier than "Date from"');
            return;
        }

        dispatchAction(getList({
            page: 1,
            from: fromDateValue.format('YYYY-MM-D'),
            to: toDateValue.format('YYYY-MM-D'),
            client_user_id: clientAutocompleteSelected.value,
            agent_user_id: agentAutocompleteSelected.value,
            sales_director_user_id: salesDirectorAutocompleteSelected.value,
            unit_manager_user_id: unitManagerAutocompleteSelected.value,
            branch_id: branchAutocompleteSelected.value,
            payment_type_id: paymentOptionAutocompleteSelected.value,
            cashier_user_id: cashierAutocompleteSelected.value,
            area_id: areaAutocompleteSelected.value,
            client: clientAutocompleteSelected.label,
            agent: agentAutocompleteSelected.label,
            sales_director: salesDirectorAutocompleteSelected.label,
            unit_manager: unitManagerAutocompleteSelected.label,
            branch: branchAutocompleteSelected.label,
            payment_type: paymentOptionAutocompleteSelected.label,
            cashier: cashierAutocompleteSelected.label,
            area: areaAutocompleteSelected.label
        }));
    }

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Monthly Report
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="monthly-report-filter-container"
            >
                <Box
                    className="monthly-report-filter"
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            label="From"
                            value={fromDateValue}
                            onChange={(newValue) => {
                                setFromDateValue(newValue);
                            }}
                            renderInput={(params) => <TextField required {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            label="To"
                            value={toDateValue}
                            onChange={(newValue) => {
                                setToDateValue(newValue);
                            }}
                            renderInput={(params) => <TextField required {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    branchAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={branch.isAutocompleteListLoading}
                        options={branch.autocompleteList}
                        value={branchAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setBranchAutocompleteSelected(newValue)
                        }}
                        inputValue={branchAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setBranchAutocompleteInput(newInputValue);
                            onBranchAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Branch" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    areaAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={area.isAutocompleteListLoading}
                        options={area.autocompleteList}
                        value={areaAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setAreaAutocompleteSelected(newValue)
                        }}
                        inputValue={areaAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setAreaAutocompleteInput(newInputValue);
                            onAreaAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Area" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    clientAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={client.isAutocompleteListLoading}
                        options={client.autocompleteList}
                        value={clientAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setClientAutocompleteSelected(newValue)
                        }}
                        inputValue={clientAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setClientAutocompleteInput(newInputValue);
                            onClientAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Client" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    cashierAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={cashier.isAutocompleteListLoading}
                        options={cashier.autocompleteList}
                        value={cashierAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setCashierAutocompleteSelected(newValue)
                        }}
                        inputValue={cashierAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setCashierAutocompleteInput(newInputValue);
                            onCashierAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Cashier" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="daily-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    salesDirectorAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={salesDirector.isAutocompleteListLoading}
                        options={salesDirector.autocompleteList}
                        value={salesDirectorAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setSalesDirectorAutocompleteSelected(newValue)
                        }}
                        inputValue={salesDirectorAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setSalesDirectorAutocompleteInput(newInputValue);
                            onSalesDirectorAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Sales Director" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="daily-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    unitManagerAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={unitManager.isAutocompleteListLoading}
                        options={unitManager.autocompleteList}
                        value={unitManagerAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setUnitManagerAutocompleteSelected(newValue)
                        }}
                        inputValue={unitManagerAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setUnitManagerAutocompleteInput(newInputValue);
                            onUnitManagerAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Unit Manager" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="daily-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    agentAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={agent.isAutocompleteListLoading}
                        options={agent.autocompleteList}
                        value={agentAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setAgentAutocompleteSelected(newValue)
                        }}
                        inputValue={agentAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setAgentAutocompleteInput(newInputValue);
                            onAgentAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Agent" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Box
                    className="monthly-report-filter"
                >
                    <Autocomplete
                        noOptionsText="No result"
                        disablePortal
                        ListboxProps={{
                            role: 'list-box',
                            onScroll: (event: React.SyntheticEvent) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    paymentOptionAutocompleteNextPage();
                                }
                            }
                        }}
                        loading={paymentOption.isAutocompleteListLoading}
                        options={paymentOption.autocompleteList}
                        value={paymentOptionAutocompleteSelected}
                        onChange={(event: any, newValue: IAutocomplete | null) => {
                            if (newValue)
                                setPaymentOptionAutocompleteSelected(newValue)
                        }}
                        inputValue={paymentOptionAutocompleteInput}
                        onInputChange={(event, newInputValue) => {
                            setPaymentOptionAutocompleteInput(newInputValue);
                            onPaymentOptionAutocompleteInputChange(newInputValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} value={option.value} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        renderInput={(params) => <TextField {...params} label="Payment Option" />}
                        disabled={monthlyReport.isListLoading}
                    />
                </Box>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<LeaderboardIcon />}
                    sx={{
                        marginBottom: '20px'
                    }}
                    onClick={showReport}
                >
                    Show Report
                </Button>
            </Box>
            <Divider
                sx={{
                    marginBottom: '20px',
                }}
            />
            <Box
                className="monthly-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Date
                    </Typography>
                    <Typography>
                        {monthlyReport.data.from === monthlyReport.data.to ? dayjs(monthlyReport.data.from).format('MMMM D, YYYY') : `${dayjs(monthlyReport.data.from).format('MMMM D, YYYY')} - ${dayjs(monthlyReport.data.to).format('MMMM D, YYYY')}`}
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Branch
                    </Typography>
                    <Typography>
                        {monthlyReport.data.branch}
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Area
                    </Typography>
                    <Typography>
                        {monthlyReport.data.area}
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Client
                    </Typography>
                    <Typography>
                        {monthlyReport.data.client}
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Cashier
                    </Typography>
                    <Typography>
                        {monthlyReport.data.cashier}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Sales Director
                    </Typography>
                    <Typography>
                        {monthlyReport.data.sales_director}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Unit Manager
                    </Typography>
                    <Typography>
                        {monthlyReport.data.unit_manager}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Agent
                    </Typography>
                    <Typography>
                        {monthlyReport.data.agent}
                    </Typography>
                </Box>
                <Box
                    className="monthly-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Payment Option
                    </Typography>
                    <Typography>
                        {monthlyReport.data.payment_type}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Sales Director Total Commissions
                    </Typography>
                    <Typography>
                        {toMoney((Number(monthlyReport.data.totalSalesDirectorCommissionsDone) + Number(monthlyReport.data.totalSalesDirectorCommissionsNotDone)))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                        >
                            Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalSalesDirectorCommissionsDone) / ((Number(monthlyReport.data.totalSalesDirectorCommissionsDone) + Number(monthlyReport.data.totalSalesDirectorCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalSalesDirectorCommissionsDone)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="gray"
                        >
                            Not Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalSalesDirectorCommissionsNotDone) / ((Number(monthlyReport.data.totalSalesDirectorCommissionsDone) + Number(monthlyReport.data.totalSalesDirectorCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalSalesDirectorCommissionsNotDone)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Unit Manager Total Commissions
                    </Typography>
                    <Typography>
                        {toMoney((Number(monthlyReport.data.totalUnitManagerCommissionsDone) + Number(monthlyReport.data.totalUnitManagerCommissionsNotDone)))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                        >
                            Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalUnitManagerCommissionsDone) / ((Number(monthlyReport.data.totalUnitManagerCommissionsDone) + Number(monthlyReport.data.totalUnitManagerCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalUnitManagerCommissionsDone)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="gray"
                        >
                            Not Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalUnitManagerCommissionsNotDone) / ((Number(monthlyReport.data.totalUnitManagerCommissionsDone) + Number(monthlyReport.data.totalUnitManagerCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalUnitManagerCommissionsNotDone)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Agent Total Commissions
                    </Typography>
                    <Typography>
                        {toMoney((Number(monthlyReport.data.totalAgentCommissionsDone) + Number(monthlyReport.data.totalAgentCommissionsNotDone)))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                        >
                            Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalAgentCommissionsDone) / ((Number(monthlyReport.data.totalAgentCommissionsDone) + Number(monthlyReport.data.totalAgentCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalAgentCommissionsDone)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="gray"
                        >
                            Not Done Commissions
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed((monthlyReport.data.totalAgentCommissionsNotDone) / ((Number(monthlyReport.data.totalAgentCommissionsDone) + Number(monthlyReport.data.totalAgentCommissionsNotDone))) * 100, 2)}% of Total Commissions)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(monthlyReport.data.totalAgentCommissionsNotDone)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Clevin Joy Conde shares
                    </Typography>
                    <Typography>
                        {toMoney(monthlyReport.data.clevin_joy_conde_shares)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Sales Directors commission
                    </Typography>
                    <Typography>
                        {toMoney(Number(monthlyReport.data.totalSalesDirectorCommissionsDone) + Number(monthlyReport.data.totalSalesDirectorCommissionsNotDone))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Unit Managers commission
                    </Typography>
                    <Typography>
                        {toMoney(Number(monthlyReport.data.totalUnitManagerCommissionsDone) + Number(monthlyReport.data.totalUnitManagerCommissionsNotDone))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Agents commission
                    </Typography>
                    <Typography>
                        {toMoney(Number(monthlyReport.data.totalAgentCommissionsDone) + Number(monthlyReport.data.totalAgentCommissionsNotDone))}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Replicon Realty Corporation shares
                    </Typography>
                    <Typography>
                        {toMoney(monthlyReport.data.replicon_realty_corporation_shares)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: monthlyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !monthlyReport.data.to ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Total Sales
                    </Typography>
                    <Typography>
                        {toMoney(monthlyReport.data.totalSales)}
                    </Typography>
                </Box>
            </Box>
            {/* <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                sx={{
                    marginTop: '20px'
                }}
            >
                Print Report
            </Button> */}
            <Divider
                sx={{
                    marginBottom: '20px',
                    marginTop: '20px'
                }}
            />
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="monthly-report-table"
            >
                {monthlyReport.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Month / Year</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Directors Commission Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Directors Commission Not Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Managers Commission Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Managers Commission Not Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agents Commission Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agents Commission Not Done</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>% of Total Sales</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {monthlyReport.list && monthlyReport.list.length > 0
                                ?
                                monthlyReport.list.map(item => {
                                    return (
                                        <TableRow key={item.month}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.month.replace(/\s\s+/g, ' ')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_sales)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_sales_director_commissions_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_sales_director_commissions_not_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_unit_manager_commissions_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_unit_manager_commissions_not_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_agent_commissions_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_agent_commissions_not_done)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toFixed(item.total_sales / monthlyReport.data.totalSales * 100, 2)}%</TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
        </>
    )
}

export default MonthlyReport;
