import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { IDailyReportData, IDailyReportListItem } from '../../types/report/daily';

interface IListParams {
    page: number,
    from: string,
    to: string,
    client_user_id: number,
    agent_user_id: number,
    sales_director_user_id: number,
    unit_manager_user_id: number,
    branch_id: number,
    payment_type_id: number,
    cashier_user_id: number,
    area_id: number,
    client: string,
    agent: string,
    sales_director: string,
    unit_manager: string,
    branch: string,
    payment_type: string,
    cashier: string,
    area: string
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()],
            ['from', queryParams.from],
            ['to', queryParams.to]
        ];

        if (queryParams.client_user_id > 0)
            paramsData.push(['client_user_id', queryParams.client_user_id.toString()]);

        if (queryParams.agent_user_id > 0)
            paramsData.push(['agent_user_id', queryParams.agent_user_id.toString()]);

        if (queryParams.sales_director_user_id > 0)
            paramsData.push(['sales_director_user_id', queryParams.sales_director_user_id.toString()]);

        if (queryParams.unit_manager_user_id > 0)
            paramsData.push(['unit_manager_user_id', queryParams.unit_manager_user_id.toString()]);

        if (queryParams.branch_id > 0)
            paramsData.push(['branch_id', queryParams.branch_id.toString()]);

        if (queryParams.payment_type_id > 0)
            paramsData.push(['payment_type_id', queryParams.payment_type_id.toString()]);

        if (queryParams.cashier_user_id > 0)
            paramsData.push(['cashier_user_id', queryParams.cashier_user_id.toString()]);

        if (queryParams.area_id > 0)
            paramsData.push(['area_id', queryParams.area_id.toString()]);

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + '/api/report/daily',
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        data.client = queryParams.client ? queryParams.client : 'All';
        data.agent = queryParams.agent ? queryParams.agent : 'All';
        data.sales_director = queryParams.sales_director ? queryParams.sales_director : 'All';
        data.unit_manager = queryParams.unit_manager ? queryParams.unit_manager : 'All';
        data.branch = queryParams.branch ? queryParams.branch : 'All';
        data.payment_type = queryParams.payment_type ? queryParams.payment_type : 'All';
        data.cashier = queryParams.cashier ? queryParams.cashier : 'All';
        data.area = queryParams.area ? queryParams.area : 'All';
        data.to = queryParams.to;
        data.from = queryParams.from;

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'daily-report/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const dailyReportSlice = createSlice({
    name: 'daily-report',
    initialState: {

        // List
        list: [] as IDailyReportListItem[],
        listPagination: {} as IPagination,
        data: {} as IDailyReportData,
        isListLoading: false,
        isRefetchList: false,
    },
    reducers: {

    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                    state.data = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })
    }
});

export default dailyReportSlice.reducer;