import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import Lots from '../lots';
import ClientLots from '../client-lots';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getSingle } from '../../../../store/reducers/client';
import getRole from '../../../../utils/get-role';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SinglePayment: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const client = useSelector((state: RootState) => state.client);
    const clientLot = useSelector((state: RootState) => state.clientLot);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingle(parseInt(id)));
    }, []);

    useEffect(() => {
        if (client.isRefetchList && id)
            dispatchAction(getSingle(parseInt(id)));

    }, [client.isRefetchList]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/payments')}
                    >
                        Payments
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {client.singleData.first_name} {client.singleData.middle_name} {client.singleData.last_name}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="single-payment-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: client.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {client.singleData.first_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {client.singleData.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {client.singleData.last_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {client.singleData.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {client.singleData.address}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Branch
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/branches/${client.singleData.branch_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {client.singleData.branch_name}
                            </Link>
                            : client.singleData.branch_name}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Lots (${clientLot.listPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ClientLots client_id={id ? parseInt(id) : 0} />
            </TabPanel>
        </>
    )
}

export default SinglePayment;