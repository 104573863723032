import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import Lots from '../lots';
import ClientLotPayments from '../client-lot-payments';
import LotClients from '../lot-clients';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import lot, { _delete, getSingle, getSingleForEdit, update } from '../../../../store/reducers/lot';
import { ILotEdit } from '../../../../types/lot/edit';
import { showWarningToast } from '../../../../toast/warn';
import { ILotListItem } from '../../../../types/lot/list-item';
import { ILot } from '../../../../types/lot/single';
import toMoney from '../../../../utils/to-money';
import isWholeNumber from '../../../../utils/is-whole-number';
import toFixed from '../../../../utils/to-fixed';
import getRole from '../../../../utils/get-role';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SingleLot: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id, lotId } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const _lot = useSelector((state: RootState) => state.lot);
    const lotClient = useSelector((state: RootState) => state.lotClient);
    const lotPayment = useSelector((state: RootState) => state.lotPayment);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && lotId)
            dispatchAction(getSingle(parseInt(lotId)));
    }, []);

    useEffect(() => {
        if (_lot.isRefetchList && lotId)
            dispatchAction(getSingle(parseInt(lotId)));

    }, [_lot.isRefetchList]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        area_id: id ? parseInt(id) : 0,
        lot: '',
        block: '',
        square_meters: '',
        price_per_month: '',
        months_to_pay: '',
        total_contract_price: '',
        price_per_square_meters: ''
    }
    const [editData, setEditData] = useState<ILotEdit>(editDataPlaceholder);

    const getEditData = () => {
        if (!lotId)
            return;

        dispatchAction(getSingleForEdit(parseInt(lotId)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (_lot.isEditGetSingleSuccess)
            setEditData(_lot.editSingleData);

    }, [_lot.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.lot
            || !editData.block
            || !editData.square_meters
            || !editData.price_per_month
            || !editData.months_to_pay
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (_lot.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [_lot.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<ILot>();

    const openDeleteModal = () => {

        if (!_lot.singleData)
            return;

        setDeleteData(_lot.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.lot_id)
            dispatchAction(_delete(deleteData.lot_id));
    }

    useEffect(() => {
        if (!_lot.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [_lot.isDeleteLoading]);

    useEffect(() => {

        if (_lot.isDeleteSuccess)
            navigate(`/areas/${id}`)

    }, [_lot.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/areas')}
                    >
                        Areas
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate(`/areas/${id}`)}
                    >
                        {_lot.singleData.area_name}
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Block {_lot.singleData.lot_block} Lot {_lot.singleData.lot_lot}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="single-lot-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _lot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                {getRole() === 1 ?
                <Box
                    className="single-lot-info-edit-button"
                >
                    <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                        Edit
                    </Button>
                    <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Box>
                : null }
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Block
                    </Typography>
                    <Typography>
                        {_lot.singleData.lot_block}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Lot
                    </Typography>
                    <Typography>
                        {_lot.singleData.lot_lot}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Square Meters
                    </Typography>
                    <Typography>
                        {_lot.singleData.lot_square_meters}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Price Per Square Meters
                    </Typography>
                    <Typography>
                    {toMoney(Number(_lot.singleData.lot_original_price_per_month) * Number(_lot.singleData.lot_months_to_pay) / Number(_lot.singleData.lot_square_meters))}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Months To Pay
                    </Typography>
                    <Typography>
                        {_lot.singleData.lot_months_to_pay}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Available
                    </Typography>
                    <Chip label={_lot.singleData.sold_date ? 'No' : 'Yes'} color={_lot.singleData.sold_date ? 'error' : 'success'} />
                </Box>
            </Box>
            <Box
                className="single-lot-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _lot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Total Contract Price
                    </Typography>
                    <Typography>
                        {toMoney(_lot.singleData.lot_price_per_square_meters * _lot.singleData.lot_square_meters)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-lot-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _lot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Terminated Sales
                    </Typography>
                    <Typography>
                        {toMoney(_lot.singleData.terminated_sales)}
                    </Typography>
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="primary"
                        >
                            Total Sales
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(_lot.singleData.total_sales / (_lot.singleData.lot_price_per_square_meters * _lot.singleData.lot_square_meters) * 100, 2)}% of Total Contract Price)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(_lot.singleData.total_sales)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-lot-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _lot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="error"
                        >
                            Total Balance
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(_lot.singleData.balance / (_lot.singleData.lot_price_per_square_meters * _lot.singleData.lot_square_meters) * 100, 2)}% of Total Contract Price)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(_lot.singleData.balance)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-lot-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _lot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-lot-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                        color="primary"
                    >
                        Overall Total Sales
                    </Typography>
                    <Typography>
                        {toMoney(Number(_lot.singleData.total_sales) + Number(_lot.singleData.terminated_sales))}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Clients (${lotClient.list.length})`} />
                    <Tab label={`Payments (${lotPayment.list.length})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <LotClients is_available={!_lot.singleData.sold_date} lot_id={lotId ? parseInt(lotId) : 0}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ClientLotPayments lot_id={lotId ? parseInt(lotId) : 0}/>
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="lots-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lots-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lots-modal-form-controls"
                    >
                        <TextField
                            InputLabelProps={!!editData.block ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Block"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.block}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, block: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.lot ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Lot"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.lot}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, lot: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.square_meters ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Square meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.square_meters}
                            onChange={(e) => {
                                setEditData((previousVal: ILotEdit) => {
                                    return { ...previousVal, square_meters: e.target.value };
                                })
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.price_per_square_meters ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Price Per Square Meters"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.price_per_square_meters}
                            onChange={(e) => {
                                setEditData((previousVal: ILotEdit) => {
                                    return { ...previousVal, ...{
                                        total_contract_price: (Number(previousVal.square_meters) * Number(e.target.value)).toString(),
                                        price_per_month: ((Number(previousVal.square_meters) * Number(e.target.value)) / Number(previousVal.months_to_pay)).toString(),
                                        price_per_square_meters: e.target.value
                                    } };
                                })
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <TextField
                             InputLabelProps={!!editData.total_contract_price ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Total Contract Price"
                            variant="outlined"
                            fullWidth
                            value={editData.total_contract_price}
                            disabled={true}
                        />
                        <TextField
                            InputLabelProps={!!editData.price_per_month ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Monthly Amortization"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.price_per_month}
                            disabled={true}
                        />
                        <TextField
                            InputLabelProps={!!editData.months_to_pay ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Months to pay"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.months_to_pay}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setEditData((previousVal: ILotEdit) => {
                                        return { ...previousVal, months_to_pay: e.target.value };
                                    })
                                }
                            }}
                            disabled={_lot.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={_lot.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _lot.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={_lot.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="lots-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="lots-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="lots-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete lot 'Block {deleteData?.lot_block} Lot {deleteData?.lot_lot}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={_lot.isDeleteLoading}
                            >
                                <CircularProgress
                                    size="1em"
                                    sx={{
                                        display: _lot.isDeleteLoading ? 'inherit' : 'none',
                                        marginRight: '10px'
                                    }}
                                />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={_lot.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleLot;