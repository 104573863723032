import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../cashiers';
import Lots from '../lots';
import ClientLotPayments from '../client-lot-payments';
import ClientLots from '../client-lots';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { _delete, changePassword, getSingle, getSingleForEdit, update } from '../../../../store/reducers/agent';
import { IAgentEdit } from '../../../../types/agent/edit';
import { IAgent } from '../../../../types/agent/single';
import { showWarningToast } from '../../../../toast/warn';
import { getAutocompleteList, emptyAutocompleteList } from '../../../../store/reducers/branch';
import { IAutocomplete } from '../../../../types/autocomplete';
import { IAgentChangePassword } from '../../../../types/agent/change-password';
import { IAgentListItem } from '../../../../types/agent/list-item';
import KeyIcon from '@mui/icons-material/Key';
import AgentClients from '../agent-clients';
import AgentCommissionsNotDone from '../agent-commissions-not-done';
import AgentCommissionsDone from '../agent-commissions-done';
import getRole from '../../../../utils/get-role';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SingleAgent: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const branch = useSelector((state: RootState) => state.branch);
    const agent = useSelector((state: RootState) => state.agent);
    const agentClient = useSelector((state: RootState) => state.agentClient);
    const agentCommissionDone = useSelector((state: RootState) => state.agentCommissionDone);
    const agentCommissionNotDone = useSelector((state: RootState) => state.agentCommissionNotDone);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingle(parseInt(id)));
    }, []);

    useEffect(() => {
        if (agent.isRefetchList && id)
            dispatchAction(getSingle(parseInt(id)));

    }, [agent.isRefetchList]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: '',
        branch_id: 0,
        username: '',
        is_active: false
    }
    const [editData, setEditData] = useState<IAgentEdit>(editDataPlaceholder);
    const [editBranchAutocompleteInput, setEditBranchAutocompleteInput] = useState('');
    const [editBranchAutocompleteSelected, setEditBranchAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [editAutocompleteSearchId, setEditAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [editBranchAutocompletePage, setEditBranchAutocompletePage] = useState(1);

    const getEditData = () => {
        if (!id)
            return;

        dispatchAction(getSingleForEdit(parseInt(id)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (agent.isEditGetSingleSuccess) {
            setEditData(agent.editSingleData);
            setEditBranchAutocompleteSelected({
                value: agent.editSingleData.branch_id,
                label: agent.editSingleData.branch_name
            });
            setEditBranchAutocompleteInput(agent.editSingleData.branch_name);
            dispatchAction(emptyAutocompleteList());
        }

    }, [agent.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (
            !editData.first_name
            || !editData.middle_name
            || !editData.last_name
            || !editData.contact_number
            || !editData.address
            || !editData.username
            || !(editBranchAutocompleteSelected.value && editBranchAutocompleteSelected.value > 0)
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update({ ...editData, branch_id: editBranchAutocompleteSelected.value }));
    }

    useEffect(() => {
        if (agent.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [agent.isUpdateSuccess]);

    const onEditAutocompleteInputChange = (search: string) => {

        setEditBranchAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setEditBranchAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = branch.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(editAutocompleteSearchId);

        setEditAutocompleteSearchId(setTimeout(() => editAutocompleteSearch(search), 300))
    }

    const editAutocompleteSearch = (search: string) => {
        setEditBranchAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const editAutocompleteNextPage = () => {
        if (editBranchAutocompletePage + 1 <= branch.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: editBranchAutocompletePage + 1,
                search: editBranchAutocompleteInput,
                addToCurrent: true
            }));

            setEditBranchAutocompletePage(previousVal => previousVal + 1);
        }
    }

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IAgent>();

    const openDeleteModal = () => {

        if (!agent.singleData)
            return;

        setDeleteData(agent.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!agent.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [agent.isDeleteLoading]);

    useEffect(() => {

        if (agent.isDeleteSuccess)
            navigate('/agents')

    }, [agent.isDeleteSuccess]);

    // Change password
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const changePasswordModalHandleClose = () => setIsChangePasswordModalOpen(false);
    const [changePasswordData, setChangePasswordData] = useState<IAgentChangePassword>({
        id: 0,
        password: ''
    });

    const openChangePasswordModal = () => {

        if (!id)
            return;

        setChangePasswordData({
            id: parseInt(id),
            password: ''
        });
        setIsChangePasswordModalOpen(true);
    }

    const changePasswordClickEvent = () => {

        if (!changePasswordData.password) {
            showWarningToast('Please fill up all fields');
            return;
        }

        if (changePasswordData && changePasswordData.id)
            dispatchAction(changePassword(changePasswordData));
    }

    useEffect(() => {
        if (!agent.isChangePasswordLoading)
            setIsChangePasswordModalOpen(false);

    }, [agent.isChangePasswordLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/agents')}
                    >
                        Agents
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {agent.singleData.first_name} {agent.singleData.middle_name} {agent.singleData.last_name}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="agent-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: agent.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                {getRole() === 1 ?
                    <Box
                        className="agent-info-edit-button"
                    >
                        <Button className="info-button" sx={{ marginRight: '10px' }} onClick={() => openChangePasswordModal()} variant="contained" color="warning" startIcon={<KeyIcon />}>
                            Change Password
                        </Button>
                        <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                            Edit
                        </Button>
                        <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Box>
                    : null}
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {agent.singleData.first_name}
                    </Typography>
                </Box>
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {agent.singleData.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {agent.singleData.last_name}
                    </Typography>
                </Box>
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {agent.singleData.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {agent.singleData.address}
                    </Typography>
                </Box>
                <Box
                    className="agent-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Branch
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/branches/${agent.singleData.branch_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {agent.singleData.branch_name}
                            </Link>
                            : agent.singleData.branch_name}
                    </Typography>
                </Box>
                {getRole() === 1 ?
                    <Box
                        className="agent-info"
                    >
                        <Typography
                            sx={{
                                fontWeight: '700'
                            }}
                        >
                            Username
                        </Typography>
                        <Typography>
                            {agent.singleData.username}
                        </Typography>
                    </Box>
                    : null}
                {agent.singleData.is_active ? <Chip label="Active" color="success" /> : <Chip label="Not Active" color="error" />}
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Clients (${agentClient.listPagination.overallTotal})`} />
                    <Tab label={`Commissions - Not Done (${agentCommissionNotDone.listPagination.overallTotal})`} />
                    <Tab label={`Commissions - Done (${agentCommissionDone.listPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <AgentClients agent_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <AgentCommissionsNotDone agent_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <AgentCommissionsDone agent_id={id ? parseInt(id) : 0} />
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Agent
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: agent.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.first_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.first_name}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.middle_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.middle_name}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.last_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.last_name}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.contact_number ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.contact_number}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.address}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        editAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={branch.isAutocompleteListLoading}
                            options={branch.autocompleteList}
                            value={editBranchAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setEditBranchAutocompleteSelected(newValue)
                            }}
                            inputValue={editBranchAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setEditBranchAutocompleteInput(newInputValue);
                                onEditAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Branch" />}
                            disabled={agent.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.username ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Username"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.username}
                            onChange={(e) => {
                                setEditData((previousVal: IAgentEdit) => {
                                    return { ...previousVal, username: e.target.value };
                                })
                            }}
                            disabled={agent.isUpdateLoading}
                        />
                        <FormControlLabel
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}
                            control={
                                <Switch
                                    checked={editData.is_active}
                                    onChange={(e) => {
                                        setEditData((previousVal: IAgentEdit) => {
                                            return { ...previousVal, is_active: e.target.checked };
                                        })
                                    }}
                                />
                            }
                            label="Active"
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={agent.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: agent.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={agent.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Agent
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete agent '{deleteData?.first_name} {deleteData?.middle_name} {deleteData?.last_name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={agent.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: agent.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={agent.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isChangePasswordModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Change Password
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="New Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            required
                            value={changePasswordData.password}
                            onChange={(e) => {
                                setChangePasswordData((previousVal: IAgentChangePassword) => {
                                    return { ...previousVal, password: e.target.value };
                                })
                            }}
                            disabled={agent.isChangePasswordLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="warning"
                            disabled={agent.isChangePasswordLoading}
                            onClick={changePasswordClickEvent}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: agent.isChangePasswordLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={changePasswordModalHandleClose}
                            disabled={agent.isChangePasswordLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleAgent;