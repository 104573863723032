import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ILotListItem } from '../../types/lot/list-item';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { IBranch } from '../../types/branch/single';
import { showSuccessToast } from '../../toast/success';
import { ILotCreate } from '../../types/lot/create';
import { ILotEdit } from '../../types/lot/edit';
import { ILot } from '../../types/lot/single';
import toFixed from '../../utils/to-fixed';

interface IListParams {
    area_id: number,
    page: number,
    block: string,
    lot: string
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        if (queryParams.block)
            paramsData.push(['block', queryParams.block.toString()]);

        if (queryParams.lot)
            paramsData.push(['lot', queryParams.lot.toString()]);

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/area/${queryParams.area_id}/lot`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'lot/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const create = createAsyncThunk(
    'lot/create',
    async (queryParams: ILotCreate, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/area/${queryParams.area_id}/lot`,
                        {
                            lot: parseInt(queryParams.lot),
                            block: parseInt(queryParams.block),
                            square_meters: Number(queryParams.square_meters),
                            price_per_square_meters: Number(queryParams.price_per_square_meters),
                            months_to_pay: parseInt(queryParams.months_to_pay)
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully created');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

const getSingleQuery = async (id: number) => {
    try {

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/lot/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const update = createAsyncThunk(
    'lot/update',
    async (queryParams: ILotEdit, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.patch(
                        process.env.REACT_APP_API_URL!.toString() + `/api/lot/${queryParams.id}`,
                        {
                            lot: parseInt(queryParams.lot),
                            block: parseInt(queryParams.block),
                            square_meters: Number(queryParams.square_meters),
                            price_per_square_meters: Number(queryParams.price_per_square_meters),
                            months_to_pay: parseInt(queryParams.months_to_pay)
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully updated');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const _delete = createAsyncThunk(
    'lot/delete',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.delete(
                        process.env.REACT_APP_API_URL!.toString() + `/api/lot/${id}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully deleted');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getSingleForEdit = createAsyncThunk(
    'lot/getSingleForEdit',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {

            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getSingle = createAsyncThunk(
    'lot/getSingle',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {

            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const lotSlice = createSlice({
    name: 'lot',
    initialState: {

        // List
        list: [] as ILotListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // Create
        isCreateLoading: false,
        isCreateSuccess: false,

        // Edit
        isEditGetSingleLoading: false,
        isEditGetSingleSuccess: false,
        editSingleData: {} as ILotEdit,
        isUpdateLoading: false,
        isUpdateSuccess: false,

        // Delete
        isDeleteLoading: false,
        isDeleteSuccess: false,

        // Single
        isGetSingleLoading: false,
        isGetSingleSuccess: false,
        singleData: {} as ILot
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
                state.isDeleteSuccess = false;
            })

            // create
            .addCase(create.pending, state => {
                state.isRefetchList = false;
                state.isCreateSuccess = false;
                state.isCreateLoading = true;
            })
            .addCase(create.fulfilled, (state, { payload }) => {
                state.isCreateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isCreateLoading = false;
            })

            // edit get single
            .addCase(getSingleForEdit.pending, state => {
                state.isEditGetSingleSuccess = false;
                state.isEditGetSingleLoading = true;
            })
            .addCase(getSingleForEdit.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                {
                    state.editSingleData = {
                        id: payload.data.lot_id,
                        block: payload.data.lot_block,
                        lot: payload.data.lot_lot,
                        square_meters: payload.data.lot_square_meters,
                        price_per_square_meters: payload.data.lot_price_per_square_meters,
                        price_per_month: payload.data.lot_original_price_per_month,
                        months_to_pay: payload.data.lot_months_to_pay,
                        area_id: payload.data.area_id,
                        total_contract_price: toFixed(Number(payload.data.lot_price_per_square_meters) * Number(payload.data.lot_price_per_square_meters), 2).toString(),
                    };
                }

                state.isEditGetSingleSuccess = payload.success;
                state.isEditGetSingleLoading = false;
            })

            // update
            .addCase(update.pending, state => {
                state.isRefetchList = false;
                state.isUpdateSuccess = false;
                state.isUpdateLoading = true;
            })
            .addCase(update.fulfilled, (state, { payload }) => {
                state.isUpdateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isUpdateLoading = false;
            })

            // delete
            .addCase(_delete.pending, state => {
                state.isRefetchList = false;
                state.isDeleteSuccess = false;
                state.isDeleteLoading = true;
            })
            .addCase(_delete.fulfilled, (state, { payload }) => {
                state.isDeleteSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isDeleteLoading = false;
            })

            // get single
            .addCase(getSingle.pending, state => {
                state.isGetSingleSuccess = false;
                state.isGetSingleLoading = true;
            })
            .addCase(getSingle.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                    state.singleData = payload.data;

                state.isGetSingleSuccess = payload.success;
                state.isGetSingleLoading = false;
            })
    }
});

export default lotSlice.reducer;