export default (number:number) => {

    if(!number)
        return 0;

    if(Number.isNaN(number))
        return 0;

    const numberString = number.toString();

    if(numberString.includes('.'))
    {
        return Number(numberString.split('.')[0]) + 1;
    } 

    return number;
}