import React, { useEffect, useState } from 'react';
import './styles.css';
import { Box } from '@mui/system';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Typography, Button, Badge } from '@mui/material';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Person2Icon from '@mui/icons-material/Person2';
import PaymentsIcon from '@mui/icons-material/Payments';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import StoreIcon from '@mui/icons-material/Store';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useNavigate } from 'react-router';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getClientLotStatusListDelay, getClientLotStatusListDueDate } from '../../../store/reducers/client-lot';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import getRole from '../../../utils/get-role';
import Person4Icon from '@mui/icons-material/Person4';
import Person3Icon from '@mui/icons-material/Person3';

interface IProps {
    page: string
}

const SideBar: React.FunctionComponent<IProps> = (props) => {

    const navigate = useNavigate();
    const clientLot = useSelector((state: RootState) => state.clientLot);
    const dispatchAction = useDispatch<AppDispatch>();

    const [open, setOpen] = useState(
        {
            reports: false,
            users: false
        }
    )

    useEffect(() => {
        if (getRole() !== 1)
            return;

        if (localStorage.getItem('ACCESS_TOKEN')) {
            dispatchAction(getClientLotStatusListDelay({
                page: 1,
            })
            );
            dispatchAction(getClientLotStatusListDueDate({
                page: 1,
            })
            );
        }

    }, []);

    return (
        <>
            <Box
                className="side-bar-container"
            >
                <List
                    sx={{ padding: 0, overflowX:'hidden' }}
                >
                    {getRole() === 1 || getRole() === 3?
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'areas'}
                        onClick={() => navigate('/areas')}
                    >
                        <ListItemIcon>
                            <LandscapeIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText className="side-bar-text" primary="Areas" />
                    </ListItemButton>
                    : null }
                    {getRole() === 1 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'branches'}
                            onClick={() => navigate('/branches')}
                        >
                            <ListItemIcon>
                                <StoreIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Branches" />
                        </ListItemButton>
                        : null}
                    {getRole() === 1 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'payment-options'}
                            onClick={() => navigate('/payment-options')}
                        >
                            <ListItemIcon>
                                <PaymentsIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Payment Options" />
                        </ListItemButton>
                        : null}
                    {getRole() === 1 || getRole() === 2 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'clients'}
                            onClick={() => navigate('/clients')}
                        >
                            <ListItemIcon>
                                <PermContactCalendarIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Clients" />
                        </ListItemButton>
                        : null}
                    {getRole() === 1 || getRole() === 2 ?
                        <ListItemButton
                            className="side-bar-menu"
                            onClick={() => setOpen({ ...open, users: !open.users })}
                        >
                            <ListItemIcon>
                                <PeopleAltIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Users" />
                            {open.users ? <ExpandLess className="side-bar-expand-less"/> : <ExpandMore className="side-bar-expand-more"/>}
                        </ListItemButton>
                        : null}
                    <Collapse in={open.users} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {getRole() === 1 ?
                                <ListItemButton
                                    className="side-bar-menu hidden-side-bar-menu"
                                    sx={{ pl: 4 }}
                                    selected={props.page === 'cashiers'}
                                    onClick={() => navigate('/cashiers')}
                                >
                                    <ListItemIcon>
                                        <Person2Icon />
                                    </ListItemIcon>
                                    <ListItemText className="side-bar-text" primary="Cashiers" />
                                </ListItemButton>
                                : null}
                            {getRole() === 1 || getRole() === 2 ?
                                <ListItemButton
                                    className="side-bar-menu hidden-side-bar-menu"
                                    sx={{ pl: 4 }}
                                    selected={props.page === 'sales-directors'}
                                    onClick={() => navigate('/sales-directors')}
                                >
                                    <ListItemIcon>
                                        <Person4Icon />
                                    </ListItemIcon>
                                    <ListItemText className="side-bar-text" primary="Sales Directors" />
                                </ListItemButton>
                                : null}
                            {getRole() === 1 || getRole() === 2 ?
                                <ListItemButton
                                    className="side-bar-menu hidden-side-bar-menu"
                                    sx={{ pl: 4 }}
                                    selected={props.page === 'unit-managers'}
                                    onClick={() => navigate('/unit-managers')}
                                >
                                    <ListItemIcon>
                                        <Person3Icon />
                                    </ListItemIcon>
                                    <ListItemText className="side-bar-text" primary="Unit Managers" />
                                </ListItemButton>
                                : null}
                            {getRole() === 1 || getRole() === 2 ?
                                <ListItemButton
                                    className="side-bar-menu hidden-side-bar-menu"
                                    sx={{ pl: 4 }}
                                    selected={props.page === 'agents'}
                                    onClick={() => navigate('/agents')}
                                >
                                    <ListItemIcon>
                                        <SupportAgentIcon />
                                    </ListItemIcon>
                                    <ListItemText className="side-bar-text" primary="Agents" />
                                </ListItemButton>
                                : null}
                        </List>
                    </Collapse>
                    {getRole() === 1 ?
                        <ListItemButton
                            className="side-bar-menu"
                            onClick={() => setOpen({ ...open, reports: !open.reports })}
                        >
                            <ListItemIcon>
                                <AssessmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Reports" />
                            {open.reports ? <ExpandLess className="side-bar-expand-less" /> : <ExpandMore className="side-bar-expand-more"/>}
                        </ListItemButton>
                        : null}
                    <Collapse in={open.reports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                className="side-bar-menu hidden-side-bar-menu"
                                sx={{ pl: 4 }}
                                selected={props.page === 'daily-report'}
                                onClick={() => navigate('/reports/daily')}
                            >
                                <ListItemIcon>
                                    <TodayIcon />
                                </ListItemIcon>
                                <ListItemText className="side-bar-text" primary="Daily" />
                            </ListItemButton>
                            <ListItemButton
                                className="side-bar-menu hidden-side-bar-menu"
                                sx={{ pl: 4 }}
                                selected={props.page === 'monthly-report'}
                                onClick={() => navigate('/reports/monthly')}
                            >
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText className="side-bar-text" primary="Monthly" />
                            </ListItemButton>
                            <ListItemButton
                                className="side-bar-menu hidden-side-bar-menu"
                                sx={{ pl: 4 }}
                                selected={props.page === 'yearly-report'}
                                onClick={() => navigate('/reports/yearly')}
                            >
                                <ListItemIcon>
                                    <CalendarMonthIcon />
                                </ListItemIcon>
                                <ListItemText className="side-bar-text" primary="Yearly" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    {getRole() === 1 || getRole() === 2 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'payments'}
                            onClick={() => navigate('/payments')}
                        >
                            <ListItemIcon>
                                <PointOfSaleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Payments" />
                        </ListItemButton>
                        : null}
                    {getRole() === 1 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'due-dates'}
                            onClick={() => navigate('/due-dates')}
                        >
                            <ListItemIcon>
                                <Badge badgeContent={clientLot.dueDateListPagination.overallTotal} color="error">
                                    <EventAvailableIcon color="primary" />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Due Dates" />
                        </ListItemButton>
                        : null}
                    {getRole() === 1 ?
                        <ListItemButton
                            className="side-bar-menu"
                            selected={props.page === 'delays'}
                            onClick={() => navigate('/delays')}
                        >
                            <ListItemIcon>
                                <Badge badgeContent={clientLot.delayListPagination.overallTotal} color="error">
                                    <EventBusyIcon color="primary" />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText className="side-bar-text" primary="Delays" />
                        </ListItemButton>
                        : null}
                </List>
            </Box>
        </>
    )
}

export default SideBar