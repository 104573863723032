import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getClientLotStatusListDueDate } from '../../../../store/reducers/client-lot';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';
import toFixed from '../../../../utils/to-fixed';
import addOne from '../../../../utils/add-one';

const DueDates: React.FunctionComponent = () => {
    // Global
    const navigate = useNavigate();
    const clientLot = useSelector((state: RootState) => state.clientLot);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getClientLotStatusListDueDate({
            page: value
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getClientLotStatusListDueDate({
                page: 1,
            })
            );
    }, []);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Due Dates
                    </Link>
                </Breadcrumbs>
            </Box>
            {/* <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
            >
                Print Page
            </Button> */}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="due-dates-table"
            >
                {clientLot.isDueDateListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Price Per Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sales Director</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit Manager</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent Contact Number</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Agent Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Contact Number</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Count (Months)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Monthly Amortization</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Delay (Days)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientLot.dueDateList && clientLot.dueDateList.length > 0
                                ?
                                clientLot.dueDateList.map(item => {
                                    return (
                                        <TableRow>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/areas/${item.area_id}`)}
                                                >
                                                    {item.area_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/areas/${item.area_id}}/lot/${item.lot_id}`)}
                                                >
                                                    Block {item.lot_block} Lot {item.lot_lot}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_square_meters}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(Number(item.lot_price_per_square_meters))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/sales-directors/${item.sales_director_id}`)}
                                                >
                                                    {item.sales_director_first_name} {item.sales_director_middle_name} {item.sales_director_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/unit-managers/${item.unit_manager_id}`)}
                                                >
                                                    {item.unit_manager_first_name} {item.unit_manager_middle_name} {item.unit_manager_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/agents/${item.agent_id}`)}
                                                >
                                                    {item.agent_first_name} {item.agent_middle_name} {item.agent_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.agent_contact_number}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.agent_address}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/branches/${item.agent_branch_id}`)}
                                                >
                                                    {item.agent_branch_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.client_contact_number}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.client_address}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/branches/${item.client_branch_id}`)}
                                                >
                                                    {item.client_branch_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_count} / {item.lot_months_to_pay}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count)))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{(item.delayed_days > 0 ? item.delayed_days : 0)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_date).format('MMMM D, YYYY hh:mm A') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_due_date ? dayjs(item.last_payment_due_date).format('MMMM D, YYYY') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.sold_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={dayjs(item.due_date).format('MMMM D, YYYY')} variant="outlined" color={item.delayed_days > 0 ? 'error' : 'primary'} />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/payments/${item.client_id}/lot/${item.client_lot_id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!clientLot.isDueDateListLoading && clientLot.dueDateListPagination && clientLot.dueDateListPagination.total > 0 ?
                <Box
                    className="due-dates-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {clientLot.dueDateListPagination.fromData} - {clientLot.dueDateListPagination.toData} out of {clientLot.dueDateListPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={clientLot.dueDateListPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
        </>
    )
}

export default DueDates;