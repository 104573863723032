import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList } from '../../../../store/reducers/unit-manager-client';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import getRole from '../../../../utils/get-role';
import toFixed from '../../../../utils/to-fixed';
import addOne from '../../../../utils/add-one';

interface IProps {
    unit_manager_id: number
}

const UnitManagerClients: React.FunctionComponent<IProps> = ({ unit_manager_id }) => {
    // Global
    const navigate = useNavigate();
    const unitManagerClient = useSelector((state: RootState) => state.unitManagerClient);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            unit_manager_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                unit_manager_id
            }));
    }, []);

    useEffect(() => {
        if (unitManagerClient.isRefetchList)
            dispatchAction(getList({
                page,
                unit_manager_id
            }));

    }, [unitManagerClient.isRefetchList]);


    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="unit-manager-clients-table"
            >
                {unitManagerClient.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Price Per Square Meters</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Count (Months)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Monthly Amortization</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Delay (Days)</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Payment Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Terminate Note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unitManagerClient.list && unitManagerClient.list.length > 0
                                ?
                                unitManagerClient.list.map(item => {
                                    return (
                                        <TableRow key={item.client_lot_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}`)}
                                                    >
                                                        {item.area_name}
                                                    </Link>
                                                    : item.area_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}}/lot/${item.lot_id}`)}
                                                    >
                                                        Block {item.lot_block} Lot {item.lot_lot}
                                                    </Link>
                                                    : `Block ${item.lot_block} Lot ${item.lot_lot}`}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.lot_square_meters}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(Number(item.lot_price_per_square_meters))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_count} / {item.lot_months_to_pay}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count)))}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{(item.delayed_days > 0 ? item.delayed_days : 0)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_date).format('MMMM D, YYYY hh:mm A') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_payment_date ? dayjs(item.last_payment_due_date).format('MMMM D, YYYY') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.sold_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={dayjs(item.due_date).format('MMMM D, YYYY')} variant="outlined" color={item.delayed_days > 0 ? 'error' : 'primary'} />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {Number(addOne(item.lot_original_price_per_month) * (Number(item.lot_months_to_pay) - Number(item.payment_count))) === 0 ? <Chip label='Paid' color="primary" /> :
                                                    <Chip label={item.is_terminated ? 'Terminated' : 'Active'} color={item.is_terminated ? 'error' : 'success'} />
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.terminate_note ? item.terminate_note : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!unitManagerClient.isListLoading && unitManagerClient.listPagination && unitManagerClient.listPagination.total > 0 ?
                <Box
                    className="unit-manager-clients-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {unitManagerClient.listPagination.fromData} - {unitManagerClient.listPagination.toData} out of {unitManagerClient.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={unitManagerClient.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
        </>
    )
}

export default UnitManagerClients;