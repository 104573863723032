import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Switch, Breadcrumbs, CircularProgress, Chip, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyIcon from '@mui/icons-material/Key';
import { ISalesDirectorListItem } from '../../../../types/sales-director/list-item';
import { ISalesDirectorCreate } from '../../../../types/sales-director/create';
import { ISalesDirectorEdit } from '../../../../types/sales-director/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete, changePassword } from '../../../../store/reducers/sales-director';
import { getAutocompleteList, emptyAutocompleteList } from '../../../../store/reducers/branch';
import { IAutocomplete } from '../../../../types/autocomplete';
import { ISalesDirectorChangePassword } from '../../../../types/sales-director/change-password';
import getRole from '../../../../utils/get-role';

interface IProps {
    branch_id: number
}

const SalesDirectors: React.FunctionComponent<IProps> = ({ branch_id }) => {
    // Global
    const navigate = useNavigate();
    const salesDirector = useSelector((state: RootState) => state.salesDirector);
    const branch = useSelector((state: RootState) => state.branch);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            search: search,
            branch_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                search: '',
                branch_id
            }));
    }, []);

    useEffect(() => {
        if (salesDirector.isRefetchList)
            dispatchAction(getList({
                page,
                search,
                branch_id
            }));

    }, [salesDirector.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            page: 1,
            search: search,
            branch_id
        }));
    }

    const searchHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    const searchKeyPressHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13) {
            searchList();
        }
    }

    // Create
    const createDataPlaceholder = {
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: '',
        username: '',
        password: '',
        branch_id: 0,
    }
    const [createData, setCreateData] = useState<ISalesDirectorCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [createBranchAutocompleteInput, setCreateBranchAutocompleteInput] = useState('');
    const [createBranchAutocompleteSelected, setCreateBranchAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createAutocompleteSearchId, setCreateAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createBranchAutocompletePage, setCreateBranchAutocompletePage] = useState(1);

    const createModalHandleClose = () => {
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false)
    }

    const openCreateModal = () => {
        setCreateBranchAutocompletePage(1);
        dispatchAction(emptyAutocompleteList());
        setIsCreateModalOpen(true);
        if (createBranchAutocompleteInput)
            dispatchAction(getAutocompleteList({
                page: 1,
                search: createBranchAutocompleteInput
            }));
    }

    const createClickEvent = () => {
        if (
            !createData.first_name
            || !createData.middle_name
            || !createData.last_name
            || !createData.contact_number
            || !createData.address
            || !createData.username
            || !createData.password
            || (!(createBranchAutocompleteSelected.value && createBranchAutocompleteSelected.value > 0) && !(branch_id && branch_id > 0))
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create({ ...createData, branch_id: branch_id > 0 ? branch_id : createBranchAutocompleteSelected.value }));
    }

    useEffect(() => {
        if (salesDirector.isCreateSuccess) {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);

        }

    }, [salesDirector.isCreateSuccess]);

    const onCreateAutocompleteInputChange = (search: string) => {

        setCreateBranchAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateBranchAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = branch.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createAutocompleteSearchId);

        setCreateAutocompleteSearchId(setTimeout(() => createAutocompleteSearch(search), 300))
    }

    const createAutocompleteSearch = (search: string) => {
        setCreateBranchAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const createAutocompleteNextPage = () => {
        if (createBranchAutocompletePage + 1 <= branch.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: createBranchAutocompletePage + 1,
                search: createBranchAutocompleteInput,
                addToCurrent: true
            }));

            setCreateBranchAutocompletePage(previousVal => previousVal + 1);
        }
    }

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: '',
        branch_id: 0,
        username: '',
        is_active: false
    }
    const [editData, setEditData] = useState<ISalesDirectorEdit>(editDataPlaceholder);
    const [editBranchAutocompleteInput, setEditBranchAutocompleteInput] = useState('');
    const [editBranchAutocompleteSelected, setEditBranchAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [editAutocompleteSearchId, setEditAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [editBranchAutocompletePage, setEditBranchAutocompletePage] = useState(1);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (salesDirector.isEditGetSingleSuccess) {
            setEditData(salesDirector.editSingleData);
            setEditBranchAutocompleteSelected({
                value: salesDirector.editSingleData.branch_id,
                label: salesDirector.editSingleData.branch_name
            });
            setEditBranchAutocompleteInput(salesDirector.editSingleData.branch_name);
            dispatchAction(emptyAutocompleteList());
        }

    }, [salesDirector.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (
            !editData.first_name
            || !editData.middle_name
            || !editData.last_name
            || !editData.contact_number
            || !editData.address
            || !editData.username
            || (!(editBranchAutocompleteSelected.value && editBranchAutocompleteSelected.value > 0) && !(branch_id && branch_id > 0))
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update({ ...editData, branch_id: branch_id > 0 ? branch_id : editBranchAutocompleteSelected.value }));
    }

    useEffect(() => {
        if (salesDirector.isUpdateSuccess) {
            setIsEditModalOpen(false);
        }

    }, [salesDirector.isUpdateSuccess]);

    const onEditAutocompleteInputChange = (search: string) => {

        setEditBranchAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setEditBranchAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = branch.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(editAutocompleteSearchId);

        setEditAutocompleteSearchId(setTimeout(() => editAutocompleteSearch(search), 300))
    }

    const editAutocompleteSearch = (search: string) => {
        setEditBranchAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const editAutocompleteNextPage = () => {
        if (editBranchAutocompletePage + 1 <= branch.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: editBranchAutocompletePage + 1,
                search: editBranchAutocompleteInput,
                addToCurrent: true
            }));

            setEditBranchAutocompletePage(previousVal => previousVal + 1);
        }
    }

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<ISalesDirectorListItem>();

    const openDeleteModal = (data: ISalesDirectorListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!salesDirector.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [salesDirector.isDeleteLoading]);

    // Change password
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const changePasswordModalHandleClose = () => setIsChangePasswordModalOpen(false);
    const [changePasswordData, setChangePasswordData] = useState<ISalesDirectorChangePassword>({
        id: 0,
        password: ''
    });

    const openChangePasswordModal = (data: ISalesDirectorListItem) => {
        setChangePasswordData({
            id: data.id,
            password: ''
        });
        setIsChangePasswordModalOpen(true);
    }

    const changePasswordClickEvent = () => {

        if (!changePasswordData.password) {
            showWarningToast('Please fill up all fields');
            return;
        }

        if (changePasswordData && changePasswordData.id)
            dispatchAction(changePassword(changePasswordData));
    }

    useEffect(() => {
        if (!salesDirector.isChangePasswordLoading)
            setIsChangePasswordModalOpen(false);

    }, [salesDirector.isChangePasswordLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Sales Directors
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="sales-directors-search-container"
            >
                <FormControl
                    variant="outlined"
                    className="sales-directors-search-input"
                    fullWidth
                >
                    <InputLabel>Search name</InputLabel>
                    <OutlinedInput
                        label="Search name"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={searchList}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={searchHandleChange}
                        value={search}
                        onKeyUp={searchKeyPressHandle}
                    />
                </FormControl>
            </Box>
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openCreateModal}
                >
                    Create New
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="sales-directors-table"
            >
                {salesDirector.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>First Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Middle Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Contact #</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Branch</TableCell>
                                {getRole() === 1 ?
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Username</TableCell>
                                : null }
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Active</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {salesDirector.list && salesDirector.list.length > 0
                                ?
                                salesDirector.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.first_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.middle_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.contact_number}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.address}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link onClick={() => navigate(`/branches/${item.branch_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                                        {item.branch_name}
                                                    </Link>
                                                    : item.branch_name}
                                            </TableCell>
                                            {getRole() === 1 ?
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.username}</TableCell>
                                            : null }
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {item.is_active ? <Chip label="Active" color="success" /> : <Chip label="Not Active" color="error" />}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/sales-directors/${item.id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                    {getRole() === 1 ?
                                                        <>
                                                            <Button onClick={() => getEditData(item.id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                                Edit
                                                            </Button>
                                                            <Button onClick={() => openChangePasswordModal(item)} variant="contained" color="warning" startIcon={<KeyIcon />}>
                                                                Change Password
                                                            </Button>
                                                            <Button onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                                Delete
                                                            </Button>
                                                        </>
                                                        : null}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!salesDirector.isListLoading && salesDirector.listPagination && salesDirector.listPagination.total > 0 ?
                <Box
                    className="sales-directors-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {salesDirector.listPagination.fromData} - {salesDirector.listPagination.toData} out of {salesDirector.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={salesDirector.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}

            <Modal
                open={isCreateModalOpen}
                className="sales-directors-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="sales-directors-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Sales Director
                    </Typography>

                    <Divider />

                    <Box
                        className="sales-directors-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.first_name}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.middle_name}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.last_name}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.contact_number}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.address}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        {branch_id === 0 ?
                            <Autocomplete
                                noOptionsText="No result"
                                disablePortal
                                ListboxProps={{
                                    role: 'list-box',
                                    onScroll: (event: React.SyntheticEvent) => {
                                        const listboxNode = event.currentTarget;
                                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                            createAutocompleteNextPage();
                                        }
                                    }
                                }}
                                loading={branch.isAutocompleteListLoading}
                                options={branch.autocompleteList}
                                value={createBranchAutocompleteSelected}
                                onChange={(event: any, newValue: IAutocomplete | null) => {
                                    if (newValue)
                                        setCreateBranchAutocompleteSelected(newValue)
                                }}
                                inputValue={createBranchAutocompleteInput}
                                onInputChange={(event, newInputValue) => {
                                    setCreateBranchAutocompleteInput(newInputValue);
                                    onCreateAutocompleteInputChange(newInputValue);
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} value={option.value} key={option.value}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                                sx={{
                                    display: 'block',
                                    marginBottom: '10px'
                                }}
                                renderInput={(params) => <TextField required {...params} label="Branch" />}
                                disabled={salesDirector.isCreateLoading}
                            />
                            : null}
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Username"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.username}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, username: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.password}
                            onChange={(e) => {
                                setCreateData((previousVal: ISalesDirectorCreate) => {
                                    return { ...previousVal, password: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={salesDirector.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: salesDirector.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={salesDirector.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isEditModalOpen}
                className="sales-directors-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="sales-directors-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Sales Director
                    </Typography>

                    <Divider />

                    <Box
                        className="sales-directors-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: salesDirector.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.first_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.first_name}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.middle_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.middle_name}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.last_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.last_name}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.contact_number ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.contact_number}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.address}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        {branch_id === 0 ?
                            <Autocomplete
                                noOptionsText="No result"
                                disablePortal
                                ListboxProps={{
                                    role: 'list-box',
                                    onScroll: (event: React.SyntheticEvent) => {
                                        const listboxNode = event.currentTarget;
                                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                            editAutocompleteNextPage();
                                        }
                                    }
                                }}
                                loading={branch.isAutocompleteListLoading}
                                options={branch.autocompleteList}
                                value={editBranchAutocompleteSelected}
                                onChange={(event: any, newValue: IAutocomplete | null) => {
                                    if (newValue)
                                        setEditBranchAutocompleteSelected(newValue)
                                }}
                                inputValue={editBranchAutocompleteInput}
                                onInputChange={(event, newInputValue) => {
                                    setEditBranchAutocompleteInput(newInputValue);
                                    onEditAutocompleteInputChange(newInputValue);
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} value={option.value} key={option.value}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                                sx={{
                                    display: 'block',
                                    marginBottom: '10px'
                                }}
                                renderInput={(params) => <TextField required {...params} label="Branch" />}
                                disabled={salesDirector.isUpdateLoading}
                            />
                            : null}
                        <TextField
                            InputLabelProps={!!editData.username ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Username"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.username}
                            onChange={(e) => {
                                setEditData((previousVal: ISalesDirectorEdit) => {
                                    return { ...previousVal, username: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isUpdateLoading}
                        />
                        <FormControlLabel
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}
                            control={
                                <Switch
                                    checked={editData.is_active}
                                    onChange={(e) => {
                                        setEditData((previousVal: ISalesDirectorEdit) => {
                                            return { ...previousVal, is_active: e.target.checked };
                                        })
                                    }}
                                />
                            }
                            label="Active"
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={salesDirector.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: salesDirector.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={salesDirector.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="sales-directors-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="sales-directors-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete SalesDirector
                    </Typography>

                    <Divider />

                    <Box
                        className="sales-directors-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete salesDirector '{deleteData?.first_name} {deleteData?.middle_name} {deleteData?.last_name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={salesDirector.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: salesDirector.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={salesDirector.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isChangePasswordModalOpen}
                className="sales-directors-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="sales-directors-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Change Password
                    </Typography>

                    <Divider />

                    <Box
                        className="sales-directors-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="New Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            required
                            value={changePasswordData.password}
                            onChange={(e) => {
                                setChangePasswordData((previousVal: ISalesDirectorChangePassword) => {
                                    return { ...previousVal, password: e.target.value };
                                })
                            }}
                            disabled={salesDirector.isChangePasswordLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="warning"
                            disabled={salesDirector.isChangePasswordLoading}
                            onClick={changePasswordClickEvent}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: salesDirector.isChangePasswordLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={changePasswordModalHandleClose}
                            disabled={salesDirector.isChangePasswordLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SalesDirectors;