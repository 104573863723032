import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Cashiers from '../cashiers';
import Agents from '../agents';
import Clients from '../clients';
import Lots from '../lots';
import ClientLotPayments from '../client-lot-payments';
import ClientLots from '../client-lots';
import PaymentClientLotPayments from '../payment-client-lot-payments';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getSingle as getSingleClient } from '../../../../store/reducers/client';
import { getSingle as getSingleClientLot, terminate } from '../../../../store/reducers/client-lot';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';
import toFixed from '../../../../utils/to-fixed';
import getRole from '../../../../utils/get-role';
import { IClientLot } from '../../../../types/client-lot/single';
import { ITerminate } from '../../../../types/lot/terminate';
import addOne from '../../../../utils/add-one';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SinglePaymentClientLot: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const client = useSelector((state: RootState) => state.client);
    const clientLot = useSelector((state: RootState) => state.clientLot);

    // Client
    const { id } = useParams();

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingleClient(parseInt(id)));
    }, []);

    useEffect(() => {
        if (client.isRefetchList && id)
            dispatchAction(getSingleClient(parseInt(id)));

    }, [client.isRefetchList]);

    // Client lot
    const { clientLotId } = useParams();

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && clientLotId)
            dispatchAction(getSingleClientLot(parseInt(clientLotId)));
    }, []);

    useEffect(() => {
        if ((clientLot.isRefetchList || clientLot.isRefetchGetSingle) && clientLotId)
            dispatchAction(getSingleClientLot(parseInt(clientLotId)));

    }, [clientLot.isRefetchList, clientLot.isRefetchGetSingle]);

    // Terminate
    const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);
    const terminateModalHandleClose = () => setIsTerminateModalOpen(false);
    const [terminateData, setTerminateData] = useState<ITerminate>({
        lot_id: 0,
        terminate_note: '',
        lot_block: 0,
        lot_lot: 0,
        is_permanently_delete: false
    });

    const openTerminateModal = () => {
        setTerminateData({
            lot_id: clientLot.singleData.lot_id,
            terminate_note: '',
            lot_block: 0,
            lot_lot: 0,
            is_permanently_delete: false
        });
        setIsTerminateModalOpen(true);
    }

    const terminateClickEvent = () => {
        dispatchAction(terminate(terminateData));
    }

    useEffect(() => {
        if (!clientLot.isTerminateLoading)
            setIsTerminateModalOpen(false);

    }, [clientLot.isTerminateLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/payments')}
                    >
                        Payments
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate(`/payments/${id}`)}
                    >
                        {client.singleData.first_name} {client.singleData.middle_name} {client.singleData.last_name}
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        {clientLot.singleData.area_name} Block {clientLot.singleData.lot_block} Lot {clientLot.singleData.lot_lot}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="single-payment-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: client.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {client.singleData.first_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {client.singleData.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {client.singleData.last_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {client.singleData.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {client.singleData.address}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Branch
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/branches/${client.singleData.branch_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {client.singleData.branch_name}
                            </Link>
                            : client.singleData.branch_name}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Area
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/areas/${clientLot.singleData.area_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {clientLot.singleData.area_name}
                            </Link>
                            : clientLot.singleData.area_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Lot
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/areas/${clientLot.singleData.area_id}/lot/${clientLot.singleData.lot_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                Block {clientLot.singleData.lot_block} Lot {clientLot.singleData.lot_lot}
                            </Link>
                            : `Block ${clientLot.singleData.lot_block} Lot ${clientLot.singleData.lot_lot}`}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Square Meters
                    </Typography>
                    <Typography>
                        {clientLot.singleData.lot_square_meters}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Price Per Square Meters
                    </Typography>
                    <Typography>
                    {toMoney(Number(clientLot.singleData.lot_original_price_per_month) * Number(clientLot.singleData.lot_months_to_pay) / Number(clientLot.singleData.lot_square_meters))}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Monthly Amortization
                    </Typography>
                    <Typography>
                        {toMoney(addOne(clientLot.singleData.lot_original_price_per_month))}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Total Contract Price
                    </Typography>
                    <Typography>
                        {toMoney(clientLot.singleData.lot_price_per_square_meters * clientLot.singleData.lot_square_meters)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Sales Director
                    </Typography>
                    <Typography>
                        <Link onClick={() => navigate(`/sales-directors/${clientLot.singleData.sales_director_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                            {clientLot.singleData.sales_director_first_name} {clientLot.singleData.sales_director_middle_name} {clientLot.singleData.sales_director_last_name}
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Unit Manager
                    </Typography>
                    <Typography>
                        <Link onClick={() => navigate(`/unit-managers/${clientLot.singleData.unit_manager_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                            {clientLot.singleData.unit_manager_first_name} {clientLot.singleData.unit_manager_middle_name} {clientLot.singleData.unit_manager_last_name}
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Agent
                    </Typography>
                    <Typography>
                        <Link onClick={() => navigate(`/agents/${clientLot.singleData.agent_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                            {clientLot.singleData.agent_first_name} {clientLot.singleData.agent_middle_name} {clientLot.singleData.agent_last_name}
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Conde %
                    </Typography>
                    <Typography>
                        {clientLot.singleData.cj_percent}%
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Sales Director %
                    </Typography>
                    <Typography>
                        {clientLot.singleData.sales_director_percent}%
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Unit Manager %
                    </Typography>
                    <Typography>
                        {clientLot.singleData.unit_manager_percent}%
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Agent %
                    </Typography>
                    <Typography>
                        {clientLot.singleData.agent_percent}%
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Sold Date
                    </Typography>
                    <Typography color="gray">
                        {dayjs(clientLot.singleData.sold_date).format('MMMM D, YYYY hh:mm A')}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Payment Count
                    </Typography>
                    <Typography>
                        {clientLot.singleData.payment_count} / {clientLot.singleData.lot_months_to_pay}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                        >
                            Total Payed
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(clientLot.singleData.total_payed / (addOne(clientLot.singleData.lot_original_price_per_month) * clientLot.singleData.lot_months_to_pay) * 100, 2)}%)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(clientLot.singleData.total_payed)}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        height: '100%',
                        width: '100%',
                        zIndex: 3,
                        borderRadius: '10px'
                    }}>
                        <CircularProgress />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="error"
                        >
                            Balance
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(addOne(clientLot.singleData.lot_original_price_per_month) * (Number(clientLot.singleData.lot_months_to_pay) - Number(clientLot.singleData.payment_count)) / (addOne(clientLot.singleData.lot_original_price_per_month) * clientLot.singleData.lot_months_to_pay) * 100, 2)}%)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(addOne(clientLot.singleData.lot_original_price_per_month) * (Number(clientLot.singleData.lot_months_to_pay) - Number(clientLot.singleData.payment_count)))}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Delay (Days)
                    </Typography>
                    <Typography>
                        {(clientLot.singleData.delayed_days > 0 ? clientLot.singleData.delayed_days : 0)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Due Date
                    </Typography>
                    <Chip label={dayjs(clientLot.singleData.due_date).format('MMMM D, YYYY')} variant="outlined" color={clientLot.singleData.delayed_days > 0 ? 'error' : 'primary'} />
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Status
                    </Typography>
                    {
                        Number(clientLot.singleData.balance) === 0 ? <Chip label='Paid' color="primary" /> :
                        <Chip label={clientLot.singleData.is_terminated ? 'Terminated' : 'Active'} color={clientLot.singleData.is_terminated ? 'error' : 'success'} />
                    }
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientLot.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Terminate Note
                    </Typography>
                    <Typography>
                        {clientLot.singleData.terminate_note ? clientLot.singleData.terminate_note : 'N/A'}
                    </Typography>
                </Box>
            </Box>
            <Button sx={{ marginTop: '20px', display: getRole() === 1 ? 'default' : 'none' }} disabled={clientLot.singleData.is_terminated} onClick={openTerminateModal} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                Terminate
            </Button>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Payments (${clientLot.paymentListPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <PaymentClientLotPayments is_paid={Number(clientLot.singleData.payment_count) === Number(clientLot.singleData.lot_months_to_pay)} client_lot_id={clientLotId ? parseInt(clientLotId) : 0} />
            </TabPanel>
            <Modal
                open={isTerminateModalOpen}
                className="agents-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="agents-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Terminate Contract
                    </Typography>

                    <Divider />

                    <Box
                        className="agents-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to terminate client lot 'Block {clientLot.singleData.lot_block} Lot {clientLot.singleData.lot_lot}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={terminateData.terminate_note}
                            onChange={(e) => {
                                setTerminateData((previousVal:ITerminate) => {
                                    return { ...previousVal, terminate_note: e.target.value };
                                })
                            }}
                            disabled={clientLot.isTerminateLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={terminateData.is_permanently_delete}
                                    onChange={(e) => {
                                        setTerminateData((previousVal: ITerminate) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={terminateClickEvent}
                            disabled={clientLot.isTerminateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientLot.isTerminateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={terminateModalHandleClose}
                            disabled={clientLot.isTerminateLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SinglePaymentClientLot;