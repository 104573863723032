import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexPage from './pages/index';
import DashboardPage from './pages/dashboard';
import './App.css';
import Receipt from './components/prints/receipt';

const App: React.FunctionComponent = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<IndexPage />} />
          <Route path='/branches' element={<DashboardPage page="branches" />} />
          <Route path='/areas' element={<DashboardPage page="areas" />} />
          <Route path='/payment-options' element={<DashboardPage page="payment-options" />} />
          <Route path='/clients' element={<DashboardPage page="clients" />} />
          <Route path='/clients/:id' element={<DashboardPage page="single-client" />} />
          <Route path='/cashiers' element={<DashboardPage page="cashiers" />} />
          <Route path='/cashiers/:id' element={<DashboardPage page="single-cashier" />} />
          <Route path='/agents' element={<DashboardPage page="agents" />} />
          <Route path='/agents/:id' element={<DashboardPage page="single-agent" />} />
          <Route path='/sales-directors' element={<DashboardPage page="sales-directors" />} />
          <Route path='/sales-directors/:id' element={<DashboardPage page="single-sales-director" />} />
          <Route path='/unit-managers' element={<DashboardPage page="unit-managers" />} />
          <Route path='/unit-managers/:id' element={<DashboardPage page="single-unit-manager" />} />
          <Route path='/branches/:id' element={<DashboardPage page="single-branch" />} />
          <Route path='/areas/:id' element={<DashboardPage page="single-area" />} />
          <Route path='/areas/:id/lot/:lotId' element={<DashboardPage page="single-lot" />} />
          <Route path='/payments' element={<DashboardPage page="payments" />} />
          <Route path='/payments/:id' element={<DashboardPage page="single-payment" />} />
          <Route path='/payments/:id/lot/:clientLotId' element={<DashboardPage page="single-payment-client-lot" />} />
          <Route path='/due-dates' element={<DashboardPage page="due-dates" />} />
          <Route path='/delays' element={<DashboardPage page="delays" />} />
          <Route path='/reports/daily' element={<DashboardPage page="daily-report" />} />
          <Route path='/reports/monthly' element={<DashboardPage page="monthly-report" />} />
          <Route path='/reports/yearly' element={<DashboardPage page="yearly-report" />} />
          <Route path='/my-account' element={<DashboardPage page="my-account" />} />
          <Route path='/receipt/' element={<Receipt />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
