import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList } from '../../../../store/reducers/sales-director-commission-done';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import getRole from '../../../../utils/get-role';

interface IProps {
    sales_director_id: number
}

const SalesDirectorCommissionsDone: React.FunctionComponent<IProps> = ({ sales_director_id }) => {

    // Global
    const navigate = useNavigate();
    const salesDirectorCommissionDone = useSelector((state: RootState) => state.salesDirectorCommissionDone);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            sales_director_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                sales_director_id
            }));
    }, []);

    useEffect(() => {
        if (salesDirectorCommissionDone.isRefetchList)
            dispatchAction(getList({
                page,
                sales_director_id
            }));

    }, [salesDirectorCommissionDone.isRefetchList]);

    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="sales-director-commissions-table"
            >
                {salesDirectorCommissionDone.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Lot Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Commission Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Done Commission Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Done Commission Note</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Commission Branch</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {salesDirectorCommissionDone.list && salesDirectorCommissionDone.list.length > 0
                                ?
                                salesDirectorCommissionDone.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}`)}
                                                    >
                                                        {item.area_name}
                                                    </Link>
                                                    : item.area_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}/lot/${item.lot_id}`)}
                                                    >
                                                        Block {item.lot_block} Lot {item.lot_lot}
                                                    </Link>
                                                    : `Block ${item.lot_block} Lot ${item.lot_lot}`}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.client_lot_created_at).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.payed_amount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.payment_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.due_date).format('MMMM D, YYYY')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.sales_director_commission)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.is_sales_director_commission_done ? dayjs(item.sales_director_commission_date).format('MMMM D, YYYY hh:mm A') : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.sales_director_commission_note}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/branches/${item.commission_branch_id}`)}
                                                    >
                                                        {item.commission_branch_name}
                                                    </Link>
                                                    : item.commission_branch_name}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!salesDirectorCommissionDone.isListLoading && salesDirectorCommissionDone.listPagination && salesDirectorCommissionDone.listPagination.total > 0 ?
                <Box
                    className="sales-director-commissions-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {salesDirectorCommissionDone.listPagination.fromData} - {salesDirectorCommissionDone.listPagination.toData} out of {salesDirectorCommissionDone.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={salesDirectorCommissionDone.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
        </>
    )
}

export default SalesDirectorCommissionsDone;