import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { IPaymentOptionListItem } from '../../../../types/payment-option/list-item';
import { IPaymentOptionCreate } from '../../../../types/payment-option/create';
import { IPaymentOptionEdit } from '../../../../types/payment-option/edit';
import { showWarningToast } from '../../../../toast/warn';
import { useNavigate } from 'react-router';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/payment-option';

const PaymentOptions: React.FunctionComponent = () => {
    // Global
    const navigate = useNavigate();
    const paymentOption = useSelector((state: RootState) => state.paymentOption);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            search: search
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                search: ''
            }));
    }, []);

    useEffect(() => {
        if (paymentOption.isRefetchList)
            dispatchAction(getList({
                page,
                search
            }));

    }, [paymentOption.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            page: 1,
            search: search
        }));
    }

    const searchHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    const searchKeyPressHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13) {
            searchList();
        }
    }

    // Create
    const createDataPlaceholder = {
        name: '',
        address: ''
    }
    const [createData, setCreateData] = useState<IPaymentOptionCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleOpen = () => setIsCreateModalOpen(true);
    const createModalHandleClose = () => {
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false)
    }

    const createClickEvent = () => {
        if (!createData.name) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create(createData));
    }

    useEffect(() => {
        if (paymentOption.isCreateSuccess) {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);
        }

    }, [paymentOption.isCreateSuccess]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: '',
        address: ''
    }
    const [editData, setEditData] = useState<IPaymentOptionEdit>(editDataPlaceholder);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (paymentOption.isEditGetSingleSuccess)
            setEditData(paymentOption.editSingleData);

    }, [paymentOption.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (paymentOption.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [paymentOption.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IPaymentOptionListItem>();

    const openDeleteModal = (data: IPaymentOptionListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!paymentOption.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [paymentOption.isDeleteLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Payment Options
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="payment-options-search-container"
            >
                <FormControl
                    variant="outlined"
                    className="branches-search-input"
                    fullWidth
                >
                    <InputLabel>Search name</InputLabel>
                    <OutlinedInput
                        label="Search name"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={searchList}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={searchHandleChange}
                        value={search}
                        onKeyUp={searchKeyPressHandle}
                    />
                </FormControl>
            </Box>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={createModalHandleOpen}
            >
                Create New
            </Button>
            <TableContainer
sx= {{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="payment-options-table"
            >
                {paymentOption.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentOption.list && paymentOption.list.length > 0
                                ?
                                paymentOption.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">{item.name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => getEditData(item.id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                        Edit
                                                    </Button>
                                                    <Button onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                        Delete
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>

            {!paymentOption.isListLoading && paymentOption.listPagination && paymentOption.listPagination.total > 0 ?
                <Box
                    className="branches-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {paymentOption.listPagination.fromData} - {paymentOption.listPagination.toData} out of {paymentOption.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={paymentOption.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isCreateModalOpen}
                className="payment-options-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="payment-options-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Payment Option
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Payment option name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.name}
                            onChange={(e) => {
                                setCreateData((previousVal: IPaymentOptionCreate) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            disabled={paymentOption.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={paymentOption.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: paymentOption.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={paymentOption.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isEditModalOpen}
                className="payment-options-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="payment-options-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Payment Option
                    </Typography>

                    <Divider />

                    <Box
                        className="payment-options-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: paymentOption.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Payment option name"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData((previousVal: IPaymentOptionEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            value={editData.name}
                            disabled={paymentOption.isUpdateLoading}
                        />
                        <Button
                            sx={{

                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="success"
                            onClick={editClickEvent}
                            disabled={paymentOption.isUpdateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: paymentOption.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={paymentOption.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="payment-options-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="payment-options-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Payment Option
                    </Typography>

                    <Divider />

                    <Box
                        className="payment-options-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete payment option '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={paymentOption.isDeleteLoading}
                            >
                                <CircularProgress
                                    size="1em"
                                    sx={{
                                        display: paymentOption.isDeleteLoading ? 'inherit' : 'none',
                                        marginRight: '10px'
                                    }}
                                />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={paymentOption.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default PaymentOptions;