import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList, setCommissionDone } from '../../../../store/reducers/unit-manager-commission-not-done';
import { IUnitManagerCommissionListItem } from '../../../../types/unit-manager-commission/list-item';
import { IUnitManagerCommissionDone } from '../../../../types/unit-manager-commission/done';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import { refreshList } from '../../../../store/reducers/unit-manager-commission-done';
import isSameBranch from '../../../../utils/is-same-branch';
import getRole from '../../../../utils/get-role';

interface IProps {
    unit_manager_id: number
}

const UnitManagerCommissionsNotDone: React.FunctionComponent<IProps> = ({ unit_manager_id }) => {

    // Global
    const navigate = useNavigate();
    const unitManagerCommissionNotDone = useSelector((state: RootState) => state.unitManagerCommissionNotDone);
    const unitManagerCommissionDone = useSelector((state: RootState) => state.unitManagerCommissionDone);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            unit_manager_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                unit_manager_id
            }));
    }, []);

    useEffect(() => {
        if (unitManagerCommissionNotDone.isRefetchList)
            dispatchAction(getList({
                page,
                unit_manager_id
            }));

    }, [unitManagerCommissionNotDone.isRefetchList]);

    // Done
    const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);
    const doneModalHandleClose = () => setIsDoneModalOpen(false);
    const doneDataPlaceholder = {
        unit_manager_id,
        note: '',
        client_lot_id: 0,
        commission_amount: 0
    }
    const [doneData, setDoneData] = useState<IUnitManagerCommissionDone>(doneDataPlaceholder);

    const openDoneModal = (data: IUnitManagerCommissionListItem) => {
        setDoneData({
            unit_manager_id,
            note: '',
            client_lot_id: data.id,
            commission_amount: data.unit_manager_commission
        });
        setIsDoneModalOpen(true);
    }

    const doneClickEvent = () => {

        if (!doneData)
            return;

        dispatchAction(setCommissionDone(doneData));
    }

    useEffect(() => {

        if (unitManagerCommissionNotDone.isDoneCommissionLoading)
            setIsDoneModalOpen(false);
        else
            setDoneData(doneDataPlaceholder);

    }, [unitManagerCommissionNotDone.isDoneCommissionLoading]);

    useEffect(() => {

        if (unitManagerCommissionNotDone.isDoneCommissionSuccess)
            dispatchAction(refreshList());

    }, [unitManagerCommissionNotDone.isDoneCommissionSuccess])

    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="unit-manager-commissions-table"
            >
                {unitManagerCommissionNotDone.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Area</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Lot</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client Lot Sold Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Due Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Commission Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Commission Branch</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unitManagerCommissionNotDone.list && unitManagerCommissionNotDone.list.length > 0
                                ?
                                unitManagerCommissionNotDone.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}`)}
                                                    >
                                                        {item.area_name}
                                                    </Link>
                                                    : item.area_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/areas/${item.area_id}/lot/${item.lot_id}`)}
                                                    >
                                                        Block {item.lot_block} Lot {item.lot_lot}
                                                    </Link>
                                                    : `Block ${item.lot_block} Lot ${item.lot_lot}`}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.client_lot_created_at).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.payed_amount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.payment_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.due_date).format('MMMM D, YYYY')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.unit_manager_commission)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {getRole() === 1 ?
                                                    <Link
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`/branches/${item.commission_branch_id}`)}
                                                    >
                                                        {item.commission_branch_name}
                                                    </Link>
                                                    : item.commission_branch_name}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Button disabled={!isSameBranch(item.commission_branch_id)} onClick={() => openDoneModal(item)} variant="contained" color="primary" startIcon={<CheckIcon />}>
                                                    Done
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!unitManagerCommissionNotDone.isListLoading && unitManagerCommissionNotDone.listPagination && unitManagerCommissionNotDone.listPagination.total > 0 ?
                <Box
                    className="unit-manager-commissions-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {unitManagerCommissionNotDone.listPagination.fromData} - {unitManagerCommissionNotDone.listPagination.toData} out of {unitManagerCommissionNotDone.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={unitManagerCommissionNotDone.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isDoneModalOpen}
                className="unit-manager-commissions-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="unit-manager-commissions-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Commission Status
                    </Typography>

                    <Divider />

                    <Box
                        className="unit-manager-commissions-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to update the unitManager commission worth of <b>{doneData.commission_amount ? toMoney(doneData.commission_amount) : 0}</b> status to done?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={doneData.note}
                            onChange={(e) => {
                                setDoneData((previousVal: IUnitManagerCommissionDone) => {
                                    return { ...previousVal, note: e.target.value };
                                })
                            }}
                            disabled={unitManagerCommissionNotDone.isDoneCommissionLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={unitManagerCommissionNotDone.isDoneCommissionLoading}
                            onClick={doneClickEvent}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: unitManagerCommissionNotDone.isDoneCommissionLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={doneModalHandleClose}
                            disabled={unitManagerCommissionNotDone.isDoneCommissionLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default UnitManagerCommissionsNotDone;