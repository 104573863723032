import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { showSuccessToast } from '../../toast/success';
import { ISalesDirectorCommissionDone } from '../../types/sales-director-commission/done';
import { ISalesDirectorCommissionListItem } from '../../types/sales-director-commission/list-item';

interface IListParams {
    sales_director_id: number,
    page: number
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()],
            ['is_done', 'true']
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/sales-director/${queryParams.sales_director_id}/commission`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'sales-director-commission-done/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const agentCommissionDone = createSlice({
    name: 'sales-director-commission-done',
    initialState: {

        // List
        list: [] as ISalesDirectorCommissionListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,
    },
    reducers: {
        refreshList: state => {
            state.isRefetchList = true;
        }
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
                state.isRefetchList = false;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })
    }
});

export default agentCommissionDone.reducer;

export const {
    refreshList
} = agentCommissionDone.actions;